import React, { useEffect, useState } from "react";

import DashboardLayout from "../../components/layouts/dashboardlayout";
import { FileUp } from "lucide-react";

function Inbox() {
  const currentUser = JSON.parse(localStorage.getItem("dit-user"))[0];

  const [inboxes, setInboxes] = useState([]);

  const getInboxes = async () => {
    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/inbox/user/${currentUser.id}`
    );

    if (response.ok) {
      const res = await response.json();
      console.log(res);
      setInboxes(res);
    }
  };

  useEffect(() => {
    getInboxes();
  }, []);

  return (
    <>
      <DashboardLayout>
        <div className="shadow py-10 px-14 rounded w-full max-w-3xl mt-8 border-2 border-blue-500">
          <div className="flex items-center gap-7">
            <img
              src="/images/inbox-icon.png"
              alt=""
              className="w-full max-w-[30px]"
            />
            <h2 className="text-2xl font-semibold text-blue-500">Inbox</h2>
          </div>
          <hr className="my-4" />

          {inboxes.length < 1 ? (
            <p>There are currently no messages.</p>
          ) : (
            <>
              {inboxes?.map((inbox) => (
                <div
                  tabIndex={0}
                  className="collapse collapse-arrow border-base-300 bg-base-200 border mb-3"
                >
                  <div className="collapse-title text-xl font-medium">
                    {inbox.title}
                    <p className="block text-sm text-gray-500">
                      {inbox.send_time}
                    </p>
                    {inbox.media !== "" && (
                      <>
                        <a href={inbox.media} target="_blank" rel="noreferrer">
                          <FileUp />
                        </a>
                      </>
                    )}
                  </div>
                  <div className="collapse-content">
                    <p>{inbox.description}</p>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </DashboardLayout>
    </>
  );
}

export default Inbox;
