import React from "react";
import PrimaryLayout from "../components/layouts/primarylayout";
import { Link } from "react-router-dom";

function Newsroom() {
  return (
    <>
      <PrimaryLayout>
        {/* Banner */}
        <section
          className="h-80 w-full bg-cover flex flex-col justify-end"
          style={{ backgroundImage: `url(/images/newsroom-page/banner.jpg)` }}
        ></section>
        {/* Banner */}

        {/* Section 01 */}
        <section className="container mx-auto py-14">
          <h2 className="text-3xl font-bold text-blue-500">Latest News</h2>
          <span className="block h-1 w-14 bg-blue-500 mt-3 mb-5"></span>

          <div className="flex flex-col gap-5">
            <div className="">
              <div className="flex items-center gap-4">
                <span className="block w-4 h-4 bg-blue-500"></span>
                <h4 className="font-bold font-blue-500">Press Releases</h4>
              </div>
              <Link
                to={"/news/1"}
                className="font-bold text-lg block hover:underline"
              >
                Mohammed Zina sentenced to 22 months in prison for insider
                dealing and fraud
              </Link>
              <span>16/02/2024</span>
            </div>
            <div className="">
              <div className="flex items-center gap-4">
                <span className="block w-4 h-4 bg-blue-500"></span>
                <h4 className="font-bold font-blue-500">News Stories</h4>
              </div>
              <Link
                to={"/news/2"}
                className="font-bold text-lg block hover:underline"
              >
                DIT secures bankruptcy of Avacade directors
              </Link>
              <span>16/02/2024</span>
            </div>
            <div className="">
              <div className="flex items-center gap-4">
                <span className="block w-4 h-4 bg-blue-500"></span>
                <h4 className="font-bold font-blue-500">Press Releases</h4>
              </div>
              <Link
                to={"/news/3"}
                className="font-bold text-lg block hover:underline"
              >
                Convicted illegal money lender imprisoned for failing to pay
                confiscation order
              </Link>
              <span>16/02/2024</span>
            </div>

            <div className="">
              <div className="flex items-center gap-4">
                <span className="block w-4 h-4 bg-blue-500"></span>
                <h4 className="font-bold font-blue-500">Company News</h4>
              </div>
              <Link
                to={"/news/4"}
                className="font-bold text-lg block hover:underline"
              >
                DIT announces new partnership with global fintech leaders
              </Link>
              <span>10/03/2024</span>
            </div>
            <div className="">
              <div className="flex items-center gap-4">
                <span className="block w-4 h-4 bg-blue-500"></span>
                <h4 className="font-bold font-blue-500">Product Releases</h4>
              </div>
              <Link
                to={"/news/5"}
                className="font-bold text-lg block hover:underline"
              >
                Innovative solutions introduced for cross-border payment
                challenges
              </Link>
              <span>25/03/2024</span>
            </div>
            <div className="">
              <div className="flex items-center gap-4">
                <span className="block w-4 h-4 bg-blue-500"></span>
                <h4 className="font-bold font-blue-500">Awards</h4>
              </div>
              <Link
                to={"/news/6"}
                className="font-bold text-lg block hover:underline"
              >
                DIT recognized for outstanding contributions to international
                trade
              </Link>
              <span>02/04/2024</span>
            </div>
          </div>
        </section>
        {/* Section 01 */}
      </PrimaryLayout>
    </>
  );
}

export default Newsroom;
