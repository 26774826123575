import React from "react";
import PrimaryLayout from "../components/layouts/primarylayout";
import { Link } from "react-router-dom";

function Home() {
  return (
    <PrimaryLayout>
      {/* Banner */}
      <section
        className="h-80 w-full bg-cover flex flex-col justify-end"
        style={{ backgroundImage: `url(/images/home-page/banner.jpg)` }}
      ></section>
      {/* Banner */}

      {/* Section 02 */}
      <section className="container mx-auto">
        <div className="w-full py-7 px-6 rounded shadow-lg bg-[#f8f9fa]">
          <p>
            DIT uses cookies to give you the best possible experience on this
            website. Please read our{" "}
            <Link to={"/"} className="text-blue-600">
              cookie policy
            </Link>
            .
          </p>
        </div>
      </section>
      {/* Section 02 */}

      {/* Section 03 */}
      <section className="container mx-auto my-12">
        <div className="flex justify-between gap-28 lg:flex-row flex-col">
          <div className="flex-1">
            <div role="tablist" className="tabs tabs-lifted">
              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab"
                aria-label="About DIT"
                defaultChecked
              />
              <div
                role="tabpanel"
                className="tab-content bg-base-100 border-base-300 rounded-box p-6"
              >
                <h2 className="text-3xl text-blue-500 font-bold">About DIT</h2>
                <span className="w-20 h-1 bg-blue-500 block mt-3 mb-5"></span>

                <div className="max-w-5xl w-full">
                  <p className="mb-3">
                    DIT is devoted to safeguarding consumers, enhancing the
                    integrity of the marketplace, and fostering competitive
                    practices for the benefit of consumers. We're at the
                    forefront of shaping a robust and ethical financial system
                    where fairness prevails and consumers are accorded their
                    rightful protections.
                  </p>
                  <p>
                    Our mission extends to buttressing a vibrant financial
                    system that upholds consumer interests, ensuring a fair and
                    equitable exchange for all participants. Our actions are
                    designed to maintain and enhance the health and success of
                    the financial environment.
                  </p>

                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-7 mt-12 mb-8">
                    <div className="card bg-base-100 shadow-xl border border-blue-500">
                      <figure className="px-10 pt-10 !justify-start">
                        <img
                          src="/images/home-page/guard.png"
                          alt="Guard"
                          className="rounded-xl max-w-[70px] w-full"
                        />
                      </figure>
                      <div className="card-body items-left text-left">
                        <h2 className="card-title">Protecting consumers</h2>
                        <span className="w-14 h-1 bg-blue-500 block mt-3 mb-5"></span>
                        <p>
                          We protect consumers from the harm caused by bad
                          conduct in financial services.
                        </p>
                        <div className="card-actions">
                          <Link
                            to={"/protect"}
                            className="btn bg-blue-500 text-white"
                          >
                            Find out how
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card bg-base-100 shadow-xl border border-blue-500">
                      <figure className="px-10 pt-10 !justify-start">
                        <img
                          src="/images/home-page/network.png"
                          alt="Guard"
                          className="rounded-xl max-w-[70px] w-full"
                        />
                      </figure>
                      <div className="card-body items-left text-left">
                        <h2 className="card-title">
                          Enhancing market integrity
                        </h2>
                        <span className="w-14 h-1 bg-blue-500 block mt-3 mb-5"></span>
                        <p>
                          We aim to support a healthy and successful financial
                          system.
                        </p>
                        <div className="card-actions">
                          <Link
                            to={"/market-integrity"}
                            className="btn bg-blue-500 text-white"
                          >
                            Learn more
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card bg-base-100 shadow-xl border border-blue-500">
                      <figure className="px-10 pt-10 !justify-start">
                        <img
                          src="/images/home-page/network.png"
                          alt="Guard"
                          className="rounded-xl max-w-[70px] w-full"
                        />
                      </figure>
                      <div className="card-body items-left text-left">
                        <h2 className="card-title">Promoting competition</h2>
                        <span className="w-14 h-1 bg-blue-500 block mt-3 mb-5"></span>
                        <p>
                          We promote effective competition in the interests of
                          consumers and take action to address concerns.
                        </p>
                        <div className="card-actions">
                          <Link
                            to={"/competition"}
                            className="btn bg-blue-500 text-white"
                          >
                            What we do
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p>
                    We adopt an assertive and innovative approach to averting
                    harm, establishing superior standards, and instigating
                    beneficial transformations within financial services.
                  </p>

                  <h3 className="text-2xl text-blue-500 font-bold mt-7">
                    How we work
                  </h3>
                  <span className="w-20 h-1 bg-blue-500 block mt-3 mb-5"></span>

                  <div className="grid md:grid-cols-2 grid-cols-1 gap-10 mb-10">
                    <div className="">
                      <p>
                        Our expectations for financial services over the next
                        three years, how we'll measure our performance, and the
                        progress we've made against our targets.
                      </p>
                      <Link
                        to={"/strategy"}
                        className="btn bg-blue-500 text-white mt-7 mb-3"
                      >
                        Our Strategy and Business Plan →
                      </Link>
                      <Link
                        to={"/annual-report"}
                        className="btn bg-blue-500 text-white"
                      >
                        Our Annual Report 2023/24 →
                      </Link>
                    </div>
                    <div className="">
                      <img src="/images/home-page/baby.jpg" alt="" />
                    </div>
                  </div>

                  <p className="mb-3">
                    For instance, our efforts with Buy Now Pay Later providers
                    have ensured that consumers receive clear and concise
                    information, enabling them to make informed financial
                    decisions.
                  </p>
                  <p className="mb-3">
                    Our Early and High Growth Oversight initiative aids newly
                    authorized firms in navigating our regulatory framework,
                    upholding the high standards we set for the financial
                    community.
                  </p>
                  <p className="mb-3">
                    Additionally, we are proactive in identifying and addressing
                    misleading practices among credit brokers, protecting
                    consumers from the risks associated with high-cost
                    short-term credit.
                  </p>
                  <p className="mb-3">
                    Explore our live consultations or peruse our comprehensive
                    collection of publications, final rules, and other
                    informative materials.
                  </p>
                  <p>
                    DIT's diverse team encompasses professionals from law,
                    insurance, banking, policy, technology services, and more.
                    We're united in our goal to deliver financial integrity and
                    consumer protection.
                  </p>
                </div>
              </div>

              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab"
                aria-label="Our Purpose"
              />
              <div
                role="tabpanel"
                className="tab-content bg-base-100 border-base-300 rounded-box p-6"
              >
                <h2 className="text-3xl text-blue-500 font-bold">
                  Our Purpose
                </h2>
                <span className="w-20 h-1 bg-blue-500 block mt-3 mb-5"></span>

                <div className="max-w-5xl w-full">
                  <p className="mb-3">
                    At Diversified International Trade (DIT), our purpose is to
                    bridge the gap between markets on a global scale, bringing
                    together buyers and sellers from all corners of the world.
                    We aim to facilitate economic growth, support sustainable
                    practices, and drive innovation in international trade.
                  </p>
                  <p className="mb-3">
                    We are committed to providing reliable, efficient, and
                    ethical trade solutions that empower communities, support
                    development, and create value for all stakeholders involved.
                    Our purpose is rooted in the belief that commerce can be a
                    force for positive change, fostering mutual understanding
                    and prosperity across cultures.
                  </p>
                  <p>
                    By focusing on asset recovery, we give a second life to
                    underutilized resources, promoting a circular economy that
                    benefits the environment and the economy alike. Our purpose
                    is not just about profit, but about creating a more
                    connected and sustainable world through responsible trade.
                  </p>
                </div>
              </div>

              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab"
                aria-label="Our Values"
              />
              <div
                role="tabpanel"
                className="tab-content bg-base-100 border-base-300 rounded-box p-6"
              >
                <h2 className="text-3xl text-blue-500 font-bold">Our Values</h2>
                <span className="w-20 h-1 bg-blue-500 block mt-3 mb-5"></span>

                <div className="max-w-5xl w-full">
                  <p className="mb-3">
                    At Diversified International Trade (DIT), our core values
                    are the bedrock of our business practices and partnerships.
                    We uphold the highest standards of integrity, transparency,
                    and excellence in all aspects of our operations.
                  </p>
                  <p className="mb-3">
                    <span className="font-bold text-blue-500">Integrity:</span>{" "}
                    We conduct our business with unwavering integrity, ensuring
                    fairness and trust in every transaction.
                  </p>
                  <p>
                    <span className="font-bold text-blue-500">
                      Sustainability:
                    </span>
                    We are committed to sustainability, both environmentally and
                    economically, striving to make a positive impact on the
                    planet and its people.
                  </p>
                  <p>
                    <span className="font-bold text-blue-500">Innovation:</span>
                    We embrace innovation, continually seeking out new and
                    better ways to serve our clients and advance global trade.
                  </p>
                  <p>
                    These values guide our approach to business and define our
                    commitment to contributing to a better world. Our dedication
                    to these principles is unwavering as we work towards a
                    future of sustainable and inclusive growth.
                  </p>
                </div>
              </div>

              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab"
                aria-label="Board of Directors"
              />
              <div
                role="tabpanel"
                className="tab-content bg-base-100 border-base-300 rounded-box p-6"
              >
                <h2 className="text-3xl text-blue-500 font-bold">About DIT</h2>
                <span className="w-20 h-1 bg-blue-500 block mt-3 mb-5"></span>

                <div className="max-w-5xl w-full">
                  <p className="mb-3">
                    DIT is devoted to safeguarding consumers, enhancing the
                    integrity of the marketplace, and fostering competitive
                    practices for the benefit of consumers. We're at the
                    forefront of shaping a robust and ethical financial system
                    where fairness prevails and consumers are accorded their
                    rightful protections.
                  </p>
                  <p>
                    Our mission extends to buttressing a vibrant financial
                    system that upholds consumer interests, ensuring a fair and
                    equitable exchange for all participants. Our actions are
                    designed to maintain and enhance the health and success of
                    the financial environment.
                  </p>

                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-7 mt-12 mb-8">
                    <div className="card bg-base-100 shadow-xl border border-blue-500">
                      <figure className="px-10 pt-10 !justify-start">
                        <img
                          src="/images/home-page/guard.png"
                          alt="Guard"
                          className="rounded-xl max-w-[70px] w-full"
                        />
                      </figure>
                      <div className="card-body items-left text-left">
                        <h2 className="card-title">Protecting consumers</h2>
                        <span className="w-14 h-1 bg-blue-500 block mt-3 mb-5"></span>
                        <p>
                          We protect consumers from the harm caused by bad
                          conduct in financial services.
                        </p>
                        <div className="card-actions">
                          <Link
                            to={"/protect"}
                            className="btn bg-blue-500 text-white"
                          >
                            Find out how
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card bg-base-100 shadow-xl border border-blue-500">
                      <figure className="px-10 pt-10 !justify-start">
                        <img
                          src="/images/home-page/network.png"
                          alt="Guard"
                          className="rounded-xl max-w-[70px] w-full"
                        />
                      </figure>
                      <div className="card-body items-left text-left">
                        <h2 className="card-title">
                          Enhancing market integrity
                        </h2>
                        <span className="w-14 h-1 bg-blue-500 block mt-3 mb-5"></span>
                        <p>
                          We aim to support a healthy and successful financial
                          system.
                        </p>
                        <div className="card-actions">
                          <Link
                            to={"/market-integrity"}
                            className="btn bg-blue-500 text-white"
                          >
                            Learn more
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card bg-base-100 shadow-xl border border-blue-500">
                      <figure className="px-10 pt-10 !justify-start">
                        <img
                          src="/images/home-page/network.png"
                          alt="Guard"
                          className="rounded-xl max-w-[70px] w-full"
                        />
                      </figure>
                      <div className="card-body items-left text-left">
                        <h2 className="card-title">Promoting competition</h2>
                        <span className="w-14 h-1 bg-blue-500 block mt-3 mb-5"></span>
                        <p>
                          We promote effective competition in the interests of
                          consumers and take action to address concerns.
                        </p>
                        <div className="card-actions">
                          <Link
                            to={"/competition"}
                            className="btn bg-blue-500 text-white"
                          >
                            What we do
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p>
                    We adopt an assertive and innovative approach to averting
                    harm, establishing superior standards, and instigating
                    beneficial transformations within financial services.
                  </p>

                  <h3 className="text-2xl text-blue-500 font-bold mt-7">
                    How we work
                  </h3>
                  <span className="w-20 h-1 bg-blue-500 block mt-3 mb-5"></span>

                  <div className="grid md:grid-cols-2 grid-cols-1 gap-10 mb-10">
                    <div className="">
                      <p>
                        Our expectations for financial services over the next
                        three years, how we'll measure our performance, and the
                        progress we've made against our targets.
                      </p>
                      <Link
                        to={"/strategy"}
                        className="btn bg-blue-500 text-white mt-7 mb-3"
                      >
                        Our Strategy and Business Plan →
                      </Link>
                      <Link
                        to={"/annual-report"}
                        className="btn bg-blue-500 text-white"
                      >
                        Our Annual Report 2023/24 →
                      </Link>
                    </div>
                    <div className="">
                      <img src="/images/home-page/baby.jpg" alt="" />
                    </div>
                  </div>

                  <p className="mb-3">
                    For instance, our efforts with Buy Now Pay Later providers
                    have ensured that consumers receive clear and concise
                    information, enabling them to make informed financial
                    decisions.
                  </p>
                  <p className="mb-3">
                    Our Early and High Growth Oversight initiative aids newly
                    authorized firms in navigating our regulatory framework,
                    upholding the high standards we set for the financial
                    community.
                  </p>
                  <p className="mb-3">
                    Additionally, we are proactive in identifying and addressing
                    misleading practices among credit brokers, protecting
                    consumers from the risks associated with high-cost
                    short-term credit.
                  </p>
                  <p className="mb-3">
                    Explore our live consultations or peruse our comprehensive
                    collection of publications, final rules, and other
                    informative materials.
                  </p>
                  <p>
                    DIT's diverse team encompasses professionals from law,
                    insurance, banking, policy, technology services, and more.
                    We're united in our goal to deliver financial integrity and
                    consumer protection.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full max-w-xs">
            <div className="card bg-[#f0f0f0] w-full shadow-xl">
              <div className="card-body items-start text-left">
                <h2 className="card-title text-[#888]">
                  Memorandum and Articles of Association of DIT
                </h2>
                <div className="card-actions">
                  <Link
                    to={"/"}
                    className="font-bold text-lg text-blue-500 underline"
                  >
                    DIT Bylaws{" "}
                  </Link>
                </div>
              </div>
            </div>

            <div className="bg-[#f0f0f0] py-10 px-6 mt-8">
              <h2 className="text-2xl font-bold text-blue-500">Recent News</h2>
              <span className="block w-16 h-1 bg-blue-500 mt-7 mb-10"></span>

              <div className="flex flex-col gap-10">
                <div>
                  <Link
                    to={"/news/1"}
                    className="font-bold hover:underline block"
                  >
                    <span className="inline-block w-4 h-4 bg-blue-600 mr-3"></span>
                    Mohammed Zina sentenced to 22 months in prison for insider
                    dealing and fraud
                  </Link>
                  <div className="flex items-center justify-between gap-4">
                    <span className="text-xs">16/02/2024</span>
                    <span className="text-sm font-bold">Press Releases</span>
                  </div>
                </div>
                <div>
                  <Link
                    to={"/news/2"}
                    className="font-bold hover:underline block"
                  >
                    <span className="inline-block w-4 h-4 bg-blue-600 mr-3"></span>
                    DIT secures bankruptcy of Avacade directors
                  </Link>
                  <div className="flex items-center justify-between gap-4">
                    <span className="text-xs">16/02/2024</span>
                    <span className="text-sm font-bold">News Stories</span>
                  </div>
                </div>
                <div>
                  <Link
                    to={"/news/3"}
                    className="font-bold hover:underline block"
                  >
                    <span className="inline-block w-4 h-4 bg-blue-600 mr-3"></span>
                    Convicted illegal money lender imprisoned for failing to pay
                    confiscation order
                  </Link>
                  <div className="flex items-center justify-between gap-4">
                    <span className="text-xs">16/02/2024</span>
                    <span className="text-sm font-bold">Press Releases</span>
                  </div>
                </div>
                <div>
                  <Link
                    to={"/news/4"}
                    className="font-bold hover:underline block"
                  >
                    <span className="inline-block w-4 h-4 bg-blue-600 mr-3"></span>
                    DIT announces new partnership with global fintech leaders
                  </Link>
                  <div className="flex items-center justify-between gap-4">
                    <span className="text-xs">16/02/2024</span>
                    <span className="text-sm font-bold">Company News</span>
                  </div>
                </div>
                <div>
                  <Link
                    to={"/news/5"}
                    className="font-bold hover:underline block"
                  >
                    <span className="inline-block w-4 h-4 bg-blue-600 mr-3"></span>
                    Innovative solutions introduced for cross-border payment
                    challenges
                  </Link>
                  <div className="flex items-center justify-between gap-4">
                    <span className="text-xs">16/02/2024</span>
                    <span className="text-sm font-bold">Product Releases</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section 03 */}
    </PrimaryLayout>
  );
}

export default Home;
