import React from "react";
import PrimaryLayout from "../components/layouts/primarylayout";

function AnnualReport() {
  return (
    <PrimaryLayout>
      <section className="container mx-auto mt-14">
        <div
          className="mb-7 h-40 flex items-center justify-center bg-cover bg-center"
          style={{
            background: `url('/images/firms-page/banner.jpg')`,
            backgroundSize: "cover",
          }}
        >
          <h1 className="md:text-5xl text-3xl font-bold">
            Annual Report 2023/24
          </h1>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-14">
          <div className="shadow py-6 px-4">
            <h2 className="md:text-4xl text-2xl">Executive Summary</h2>
            <hr className="my-3" />
            <p>
              Reflecting on a year of challenges and triumphs, our commitment to
              financial integrity and consumer advocacy has driven our success.
              This report showcases our achievements and ongoing dedication to
              excellence.
            </p>
          </div>
          <div className="shadow py-6 px-4">
            <h2 className="md:text-4xl text-2xl">Fiscal Stewardship</h2>
            <hr className="my-3" />
            <p>
              Through prudent financial management, we've navigated economic
              uncertainties, achieving steady growth and strengthening our
              market presence.
            </p>
          </div>
        </div>
      </section>

      <section className="container mx-auto my-14 text-center">
        <h2 className="mt-14 mb-2 text-3xl font-bold">Annual Report 2023/24</h2>
        <span className="inline-block w-14 h-1 bg-blue-600 mb-6"></span>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">Executive Summary</h3>
        <p className="mb-2">
          This year, our journey through formidable challenges has been marked
          by resilience and innovation. Our dedication to upholding financial
          integrity and championing consumer rights has been the bedrock of our
          operations. This report unfolds our relentless pursuit of excellence,
          showcasing our commitment to navigating the complexities of financial
          fraud with astute guidance and strategic foresight.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">Fiscal acumen</h3>
        <p className="my-4">
          Our fiscal acumen, reinforced by vigilant oversight and strategic
          investments in cutting-edge security measures, has fortified our
          platform’s capability to offer unwavering support to those affected by
          financial misdemeanors. The robustness of our financial health has
          enabled us to sustain and amplify our efforts towards securing
          personal and financial data against unauthorized use.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">
          Consumer Empowerment
        </h3>
        <p className="my-2">
          Advocating for consumer rights and bolstering confidence through
          proactive support have been paramount in our endeavors. This year’s
          initiatives have significantly enhanced consumer autonomy, embedding
          fairness and transparency not just as core tenets but as practiced
          standards within our operations.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">Strategic Outlook</h3>
        <p className="my-2">
          Looking forward, our strategy is aimed at not only refining our core
          capabilities but also extending our reach to enlighten and protect
          against financial fraud. Through education, robust community
          engagement, and strategic alliances with leading cybersecurity
          entities, we are set to fortify the digital landscape against
          financial threats.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">
          Commitment to Community and Integrity
        </h3>
        <p className="my-2">
          Our commitment transcends market performance, touching the lives
          within the communities we serve. Through sustained outreach and
          philanthropy, we strive to address societal challenges, promoting
          socioeconomic advancement and championing the cause of sustainable
          development.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">
          Regulatory Insight and Compliance
        </h3>
        <p className="my-2">
          As regulatory landscapes evolve, we remain at the forefront of policy
          adaptation and compliance, ensuring our operations not only adhere to
          current regulations but also advocate for the enactment of policies
          that bolster consumer protection and empower our stakeholders.
        </p>
      </section>
    </PrimaryLayout>
  );
}

export default AnnualReport;
