import React from "react";
import PrimaryLayout from "../components/layouts/primarylayout";
import { Link } from "react-router-dom";

function Consumers() {
  return (
    <>
      <PrimaryLayout>
        {/* Banner */}
        <section
          className="h-80 w-full bg-cover flex flex-col justify-end"
          style={{ backgroundImage: `url(/images/consumers-page/banner.jpg)` }}
        ></section>
        {/* Banner */}

        {/* Section 01 */}
        <section className="container mx-auto py-14">
          <h2 className="text-3xl font-bold text-blue-500">
            Consumer Protection
          </h2>
          <span className="block h-1 w-14 bg-blue-500 mt-3 mb-5"></span>

          <div className="flex items-center gap-16 text-sm">
            <p>First published: 08/05/2015</p>
            <p>Last updated: 13/07/2023</p>
            <Link to={"/"} className="text-blue-500">
              See all updates
            </Link>
          </div>

          <hr className="mt-8 mb-4" />
          <p>
            Welcome to our comprehensive guide on Consumer Protection. Here,
            you'll find everything you need to know about your rights as a
            consumer, how to navigate the market safely, and what to do if you
            encounter issues.
          </p>

          <h2 className="text-blue-500 font-bold text-2xl">
            Understanding Your Consumer Rights
          </h2>
          <p>
            Consumer rights are the legal rights afforded to you as a consumer.
            These include the right to safety, the right to be informed, the
            right to choose, and the right to be heard. Understanding these
            rights is the first step in ensuring you are treated fairly.
          </p>

          <h2 className="text-blue-500 font-bold text-2xl">
            Key Consumer Protection Laws
          </h2>
          <p>
            Various laws protect consumers from unfair practices. These include
            the Consumer Protection Act, Fair Credit Reporting Act, Fair Debt
            Collection Practices Act, and more. Familiarizing yourself with
            these laws can help you understand your protections.
          </p>

          <h2 className="text-blue-500 font-bold text-2xl">
            Tips for Smart Consumers
          </h2>
          <ul className="pl-7 list-disc">
            <li>
              Always read product information carefully before purchasing.
            </li>
            <li>Keep receipts and documentation for all major purchases.</li>
            <li>Be aware of common scams and fraudulent activities.</li>
            <li>Know your rights when it comes to returns and warranties.</li>
          </ul>

          <h2 className="text-blue-500 font-bold text-2xl">
            Filing Complaints and Getting Assistance
          </h2>
          <p>
            If you have a problem with a product or service, you have the right
            to complain. Start by contacting the business directly. If that
            doesn't resolve the issue, you can file a complaint with consumer
            protection agencies.
          </p>
          <p>
            If you have any concerns or need assistance, please{" "}
            <span className="text-blue-300">
              contact our consumer support team
            </span>
            . We're here to help you navigate the marketplace safely and
            confidently.
          </p>

          <h2 className="text-blue-500 font-bold text-2xl">Useful Resources</h2>
          <p>For more information, visit the following resources:</p>
          <ul className="pl-7 list-disc">
            <li>
              <Link to={"/"} className="text-blue-400 underline">
                Consumer Protection Agency
              </Link>
            </li>
            <li>
              <Link to={"/"} className="text-blue-400 underline">
                Federal Trade Commission
              </Link>
            </li>
            <li>
              <Link to={"/"} className="text-blue-400 underline">
                Consumer Financial Protection Bureau
              </Link>
            </li>
          </ul>
        </section>
        {/* Section 01 */}
      </PrimaryLayout>
    </>
  );
}

export default Consumers;
