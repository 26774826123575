import React from "react";
import PrimaryLayout from "../components/layouts/primarylayout";

function MarketIntegrity() {
  return (
    <PrimaryLayout>
      <section className="container mx-auto my-14">
        <h2 className="mt-14 mb-2 text-3xl font-bold">
          Enhancing Market Integrity
        </h2>
        <span className="inline-block w-14 h-1 bg-blue-600 mb-6"></span>

        <p className="my-4">
          Ensuring the integrity of the financial market is paramount for
          sustaining a healthy and successful financial system. Our organization
          is dedicated to upholding the highest standards of market conduct,
          transparency, and fairness, safeguarding the system's integrity for
          all participants.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">
          Transparency and Fair Practices
        </h3>
        <p className="my-4">
          We advocate for clear and transparent communication within the
          financial markets, fostering an environment where every transaction is
          conducted openly and fairly. This commitment to transparency helps to
          build trust among market participants and contributes to a more stable
          financial system.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">
          Regulatory Compliance and Oversight
        </h3>
        <p className="my-4">
          Through rigorous oversight and enforcement of compliance standards, we
          ensure that financial institutions and market participants adhere to
          established rules and regulations, maintaining the market's integrity
          and protecting investors and consumers from malpractice.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">
          Educating and Empowering Participants
        </h3>
        <p className="my-2">
          A well-informed participant is an empowered one. We dedicate resources
          to educating consumers and investors about their rights and the risks
          involved in market activities, enabling them to make more informed
          decisions and better protect their interests.
        </p>
      </section>
    </PrimaryLayout>
  );
}

export default MarketIntegrity;
