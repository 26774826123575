import React from "react";
import PrimaryLayout from "../../components/layouts/primarylayout";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    if (!email || !password) return alert("Email and Password are required");

    const findUser = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/users/login`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    );

    if (findUser.ok) {
      const userData = await findUser.json(); // Parse the JSON from the response

      console.log(userData); // Do something with the user data

      if (userData.length < 1) {
        alert("User Not Found");
      } else {
        localStorage.setItem("dit-user", JSON.stringify(userData));
        navigate("/dashboard");
      }
    } else {
      alert("Something Went Wrong");
      console.error("Login failed", findUser.statusText);
    }
  };

  return (
    <PrimaryLayout>
      <section className="container mx-auto my-14">
        <div className="mx-auto w-full max-w-xl">
          <div className="bg-blue-100 shadow mb-5 py-7 px-6">
            <p className="text-sm">
              Remember: Your security is paramount to us. Keep your login
              credentials confidential to protect your account against
              unauthorized access.
            </p>
          </div>
          <div className="shadow py-7 px-5 bg-white">
            <h2 className="text-center text-xl font-bold">Sign In</h2>
            <form action="" onSubmit={(e) => handleLogin(e)}>
              <div className="mb-3">
                <input
                  type="text"
                  className="w-full input border border-slate-400"
                  name="email"
                  placeholder="Email"
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="w-full input border border-slate-400"
                  name="password"
                  placeholder="Password"
                />
              </div>
              <div className="mb-3">
                <button className="btn w-full bg-blue-600 text-white text-center">
                  Login
                </button>
              </div>
            </form>

            <p className="my-4">
              Not register yet?{" "}
              <Link to={"/register"} className="font-bold text-blue-500">
                Register
              </Link>
            </p>

            <p>Sign in to access your account and manage your services.</p>
          </div>
        </div>
      </section>
    </PrimaryLayout>
  );
}

export default Login;
