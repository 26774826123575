import React, { useEffect, useState } from "react";

function TransactionTotal() {
  const currentUser = JSON.parse(localStorage.getItem("dit-user"));

  const id = currentUser[0].id;

  const [ditAccounts, setDitAccounts] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [totalDitAmount, setTotalDitAmount] = useState(0);
  const [totalTrAmount, setTotalTrAmount] = useState(0);

  const getUser = async () => {
    const ditAc = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/dit/user/${id}`
    );

    if (ditAc.ok) {
      const ditAcRes = await ditAc.json();
      setDitAccounts(ditAcRes);

      const totalDit = ditAcRes.reduce((total, dit) => {
        if (dit.status === "active") {
          // Ensure the amount is converted to a number
          const amount = parseFloat(dit.amount) || 0;
          return total + amount;
        }
        return total;
      }, 0);
      setTotalDitAmount(totalDit);
    }

    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/transaction/user/${id}`
    );

    if (response.ok) {
      const res = await response.json();
      console.log(res);
      setTransaction(res);

      const totalTr = res.reduce((total, tr) => {
        if (tr.status === "pending") {
          // Ensure the amount is converted to a number
          const amount = parseFloat(tr.amount) || 0;
          return total + amount;
        }
        return total;
      }, 0);
      setTotalTrAmount(totalTr);
    }
  };

  useEffect(() => {
    getUser();
  }, [id]);

  return (
    <>
      <div className="w-full max-w-6xl grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8 mx-auto my-8">
        <div className="py-16 px-8 text-center shadow-xl rounded border border-blue-500">
          <img
            src="/images/logo.png"
            alt=""
            className="w-full max-w-[40px] mx-auto"
          />
          <p className="mt-5 mb-4">Generated</p>
          <h2
            className={`text-3xl font-bold ${
              totalDitAmount > 0 ? "text-green-500" : "text-blue-500"
            }`}
          >
            ${totalDitAmount}
          </h2>
        </div>
        <div className="py-16 px-8 text-center shadow-xl rounded border border-blue-500">
          <img
            src="/images/logo.png"
            alt=""
            className="w-full max-w-[40px] mx-auto"
          />
          <p className="mt-5 mb-4">Pending Transfer</p>
          <h2
            className={`text-3xl font-bold ${
              totalTrAmount > 0 ? "text-green-500" : "text-blue-500"
            }`}
          >
            ${totalTrAmount}
          </h2>
        </div>
        <div className="py-16 px-8 text-center shadow-xl rounded border border-blue-500">
          <img
            src="/images/logo.png"
            alt=""
            className="w-full max-w-[40px] mx-auto"
          />
          <p className="mt-5 mb-4">Transfer within same bank</p>
          <h2 className="text-3xl font-bold text-blue-500">$0.00</h2>
        </div>
        <div className="py-16 px-8 text-center shadow-xl rounded border border-blue-500">
          <img
            src="/images/logo.png"
            alt=""
            className="w-full max-w-[40px] mx-auto"
          />
          <p className="mt-5 mb-4">Transfer to other bank</p>
          <h2 className="text-3xl font-bold text-blue-500">$0.00</h2>
        </div>
      </div>
    </>
  );
}

export default TransactionTotal;
