import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <section className="bg-[#333] py-10 px-4">
      <div className="container mx-auto w-full grid md:grid-cols-5 grid-cols-1 gap-10">
        <div className="col-span-1">
          <Link to={"/"}>
            <img
              src="/images/logo-white.png"
              alt="Logo White"
              className="w-full max-w-[100px]"
            />
          </Link>
        </div>
        <div className="text-white col-span-2">
          <p className="mb-4">
            &copy; 2024 Department of International Trade and Trust (DIT). All
            rights reserved.
          </p>
          <ul>
            <li>
              <Link to={"/"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/"}>Terms of Service</Link>
            </li>
            <li>
              <Link to={"/"}>Accessibility</Link>
            </li>
            <li>
              <Link to={"/"}>
                ISO 27001 Certified for Information Security Management
              </Link>
            </li>
            <li>+44 7529 724895</li>
          </ul>
        </div>
        <div className="text-white col-span-2">
          <p>
            Dedicated to fostering digital integrity and safeguarding consumers
            in the digital age.
          </p>
        </div>
      </div>
      <hr className="container mx-auto my-7" />
      <div className="container mx-auto text-sm text-white">
        <p>&copy; 2024 DIT, All Rights Reserved</p>
        <p>Championing equitable and secure digital commerce worldwide.</p>
      </div>
    </section>
  );
}

export default Footer;
