import React from "react";
import PrimaryLayout from "../components/layouts/primarylayout";
import { Link } from "react-router-dom";

function Competition() {
  return (
    <PrimaryLayout>
      <section className="container mx-auto my-14">
        <h2 className="mt-14 mb-2 text-3xl font-bold">Promoting Competition</h2>
        <span className="inline-block w-14 h-1 bg-blue-600 mb-6"></span>

        <p className="my-4">
          Our institution stands as a bulwark against the forces that undermine
          fair competition in the marketplace. With a vigilant eye on market
          dynamics, we strive to create a landscape where consumers and firms
          thrive on the principles of equity, choice, and innovation.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">
          Enforcing Fair Play
        </h3>
        <p className="my-4">
          Our regulatory actions are driven by a commitment to economic justice.
          We actively work to identify and dismantle any anti-competitive
          practices, ensuring that no entity can unfairly tip the scales in its
          favor. Our rigorous investigative processes and enforcement mechanisms
          are designed to penalize non-compliance and discourage future
          violations.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">
          Stimulating Market Dynamism
        </h3>
        <p className="my-4">
          In the spirit of entrepreneurial growth, we support market entrants
          and small businesses in navigating the regulatory landscape. Our
          initiatives seek to lower the barriers to entry and foster an arena
          where diverse business models can flourish. By offering guidance and
          advocacy, we aim to stimulate a vibrant ecosystem ripe for economic
          progress.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">
          Encouraging Innovation
        </h3>
        <p className="my-2">
          We believe innovation is the lifeblood of competitive markets. Through
          our policies and incentives, we aim to catalyze advancements in
          services and technologies that will define the future of finance. Our
          support extends to research and development endeavors that promise to
          revolutionize consumer experiences and financial efficiency.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">
          Ensuring Market Integrity
        </h3>
        <p className="my-2">
          The integrity of financial markets is paramount in preserving consumer
          trust and stability. We operate with a mandate to foster a transparent
          and accountable financial environment, where rules are clear, and
          malpractice faces stringent consequences. Our continuous monitoring
          and supervision uphold the market's credibility, ensuring that it
          functions as a fair and orderly system for all participants.
        </p>

        <div className="my-7">
          <Link to={"#"} className="btn bg-blue-600 text-white">
            Learn More About Our Approach
          </Link>
        </div>
      </section>
    </PrimaryLayout>
  );
}

export default Competition;
