import React from "react";
import PrimaryLayout from "../components/layouts/primarylayout";
import { Link } from "react-router-dom";

function Security() {
  return (
    <>
      <PrimaryLayout>
        {/* Banner */}
        <section
          className="h-80 w-full bg-cover flex flex-col justify-end"
          style={{ backgroundImage: `url(/images/security-page/banner.jpg)` }}
        ></section>
        {/* Banner */}

        {/* Section 01 */}
        <section className="container mx-auto py-14">
          <h2 className="text-3xl font-bold text-blue-500">
            Security and Safety
          </h2>
          <span className="block h-1 w-14 bg-blue-500 mt-3 mb-5"></span>

          <div className="flex items-center gap-16 text-sm">
            <p>First published: 08/05/2015</p>
            <p>Last updated: 13/07/2023</p>
            <Link to={"/"} className="text-blue-500">
              See all updates
            </Link>
          </div>

          <hr className="mt-8 mb-4" />
          <p>
            Ensuring the security and safety of our clients' data and assets is
            our top priority. Our comprehensive approach to security encompasses
            everything from data protection to personal safety advice.
          </p>
          <hr className="mt-4 mb-8" />

          <div className="flex items-center gap-7">
            <img
              src="/images/security-page/security-side-lock-icon.png"
              alt=""
              className="w-full max-w-[50px]"
            />
            <h2 className="text-blue-500 font-bold text-2xl">
              Data Protection and Privacy
            </h2>
          </div>
          <p className="md:pl-20 p-0 mb-2">
            Our commitment to data protection and privacy is unwavering. We
            employ advanced encryption and security protocols to safeguard your
            information at all times. Learn about our strict data handling
            policies and how we ensure compliance with global data protection
            regulations.
          </p>
          <p className="md:pl-20 p-0">
            From multi-factor authentication to regular audits, our security
            framework is designed to protect personal and financial data. We are
            transparent in our practices and offer our clients the highest level
            of confidentiality.
          </p>

          <div className="flex items-center gap-7 mt-8">
            <img
              src="/images/security-page/security-center-lock-icon.png"
              alt=""
              className="w-full max-w-[50px]"
            />
            <h2 className="text-blue-500 font-bold text-2xl">
              Cybersecurity Measures
            </h2>
          </div>
          <p className="md:pl-20 p-0 mb-2">
            We continuously update our cybersecurity measures to guard against
            the latest threats, ensuring your data remains secure against
            unauthorized access and cyber attacks. Discover how we stay ahead of
            cyber threats with innovative technologies and constant vigilance.
          </p>
          <p className="md:pl-20 p-0">
            Our team of cybersecurity experts regularly conducts penetration
            testing and security training. We also collaborate with global
            security agencies to stay updated on emerging threats and best
            practices.
          </p>

          <div className="flex items-center gap-7 mt-8">
            <img
              src="/images/security-page/light-icon.png"
              alt=""
              className="w-full max-w-[50px]"
            />
            <h2 className="text-blue-500 font-bold text-2xl">
              Tips for Personal Security
            </h2>
          </div>
          <ul className="md:pl-20 pl-0">
            <li>Use strong, unique passwords for all accounts.</li>
            <li>Be cautious of phishing emails and suspicious links.</li>
            <li>
              Regularly update your software to protect against vulnerabilities.
            </li>
            <li>Enable two-factor authentication for added security.</li>
            <li>Be aware of the latest scams and how to avoid them.</li>
          </ul>
          <p className="md:pl-20 p-0">
            Stay informed and proactive about your digital security. Regularly
            review your security settings and be vigilant about sharing personal
            information.
          </p>

          <div className="flex items-center gap-7 mt-8 mb-4">
            <h2 className="text-blue-500 font-bold text-2xl">
              Frequently Asked Questions
            </h2>
          </div>
          <p className="font-bold">
            How often do you update your security protocols?
          </p>
          <p>
            Our security protocols are reviewed and updated regularly to respond
            to new threats and ensure the highest level of protection.
          </p>
          <p className="font-bold mt-4">
            What should I do if I suspect a security breach?
          </p>
          <p>
            If you suspect any breach or unusual activity, contact our support
            team immediately. Quick response is crucial for security incidents.
          </p>
          <p className="mt-7">
            For more detailed information on our security policies and tips,
            please{" "}
            <Link to={"/"} className="underline text-blue-300">
              visit our security page
            </Link>
            .
          </p>

          <hr className="my-20" />
          <div className="grid md:grid-cols-2 grid-cols-1 gap-20">
            <div>
              <h2 className="text-blue-500 font-bold text-xl">
                Latest Consultations
              </h2>
              <ul className="list-disc md:pl-14 pl-0 mt-4 mb-4">
                <li>
                  CP24/1: Financial Services Compensation Scheme - Management
                  Expenses Levy Limit 2024/25
                </li>
                <li>
                  CP23/31: Primary Markets Effectiveness Review: Feedback to
                  CP23/10 and detailed proposals for listing rules reforms
                </li>
                <li>
                  CP23/32: Improving transparency for bond and derivatives
                  markets
                </li>
                <li>
                  CP23/33: Consultation on payments to data providers and DRSP
                  forms including Policy Statement for the framework for UK
                  consolidated tape (CP23/15)
                </li>
                <li>CP23/29: Mynediad at arian parod</li>
              </ul>
              <p>
                <Link to={"/"} className="text-blue-400 underline">
                  Show all consultations
                </Link>
              </p>
            </div>
            <div>
              <h2 className="text-blue-500 font-bold text-xl">
                Latest Policy and Guidance
              </h2>
              <ul className="list-disc md:pl-14 pl-0 mt-4 mb-4">
                <li>
                  PS24/1: Temporary changes to handling rules for motor finance
                  complaints
                </li>
                <li>
                  PS23/19: Margin requirements for non-centrally cleared
                  derivatives: Amendments to BTS 2016/2251
                </li>
                <li>
                  PS23/18: Smarter Regulatory Framework: The Insurance
                  Distribution Directive
                </li>
                <li>
                  Engagement feedback on the new public offers and admissions to
                  trading regime
                </li>
                <li>
                  PS23/17: Remuneration: enhancing proportionality for small
                  firms
                </li>
              </ul>
              <p>
                <Link to={"/"} className="text-blue-400 underline">
                  Latest policy and guidance
                </Link>
              </p>
            </div>
          </div>
        </section>
        {/* Section 01 */}
      </PrimaryLayout>
    </>
  );
}

export default Security;
