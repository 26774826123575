import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layouts/dashboardlayout";
import {
  ArrowUpDown,
  Building,
  MapPin,
  NotebookPenIcon,
  NotepadText,
  UserCircle,
} from "lucide-react";
import TransactionTotal from "../../components/dashboard/transactionTotal";
import { formatInTimeZone } from "date-fns-tz";

function Transaction() {
  const currentUser = JSON.parse(localStorage.getItem("dit-user"))[0];

  const [transaction, setTransaction] = useState([]);

  const getTransaction = async () => {
    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/transaction/user/${currentUser.id}`
    );

    if (response.ok) {
      const res = await response.json();
      console.log(res);
      setTransaction(res);
    }
  };

  useEffect(() => {
    getTransaction();
  }, []);

  return (
    <>
      <DashboardLayout>
        <div>
          <div className="bg-blue-50 py-6 px-7 flex gap-4">
            <div>
              <img
                src="/images/awaiting-icon.png"
                alt=""
                className="w-full max-w-[20px]"
              />
            </div>
            <div>
              <p className="font-semibold mb-4">Transaction Overview</p>
              <p className="text-sm">
                This dashboard provides a detailed record of your transactions,
                each meticulously verified for accuracy and compliance with
                global financial regulations. Every transaction listed has been
                authenticated and is subject to continuous monitoring to uphold
                the highest standards of financial security and integrity. The
                details furnished herein include transaction identifiers,
                participant information, and transaction statuses, which are
                crucial for maintaining a transparent and secure financial
                environment.
              </p>
            </div>
          </div>

          <hr className="mt-4" />

          <div className="my-10">
            {transaction?.length < 1 ? (
              <p>No Transactions Found</p>
            ) : (
              transaction.map((tr) => (
                <>
                  <div className="shadow border border-blue-500 py-7 px-5 rounded">
                    <div className="flex items-center justify-between gap-5">
                      <div>
                        <h2 className="text-2xl font-semibold mb-4">
                          <span>Transaction </span>
                          <span>#{tr.id}</span>
                        </h2>
                        <div className="flex items-center gap-3">
                          <NotepadText />
                          <span>
                            {/* {tr.date} */}
                            {formatInTimeZone(
                              new Date(tr.date),
                              "Asia/Jerusalem",
                              "yyyy-MM-dd HH:mm:ss"
                            )}
                          </span>
                        </div>
                      </div>
                      <div>
                        <h2 className="text-2xl font-semibold text-green-blue-500 mb-3">
                          ${tr.amount}
                        </h2>
                        <button
                          className={`badge ${
                            tr.status === "pending"
                              ? "border-yellow-600 text-yellow-600"
                              : tr.status === "completed"
                              ? "border-green-600 text-green-600"
                              : "border-red-600 text-red-600"
                          }`}
                        >
                          {tr.status}
                        </button>
                      </div>
                    </div>
                    <hr className="my-4" />
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center gap-4">
                        <NotebookPenIcon />
                        <span>Reference Number:</span>
                        <span>{tr.reference}</span>
                      </div>
                      <div className="flex items-center gap-4">
                        <ArrowUpDown />
                        <span>Sender IBAN: </span>
                        <span>{tr.iban}</span>
                      </div>
                      <div className="flex items-center gap-4">
                        <UserCircle />
                        <span>Sender Account Number: </span>
                        <span>{tr.account_number}</span>
                      </div>
                      <div className="flex items-center gap-4">
                        <Building />
                        <span>Sender Bank Branch </span>
                        <span>{tr.bank_branch}</span>
                      </div>
                      <div className="flex items-center gap-4">
                        <MapPin />
                        <span>Sender Address: </span>
                        <span>{tr.address}</span>
                      </div>
                      {tr.withdrawal ? (
                        <>
                          <div className="flex items-center justify-end gap-4">
                            <button
                              className="btn bg-sky-700 text-white"
                              onClick={() => {
                                document
                                  .getElementById("transaction_modal")
                                  .showModal();
                              }}
                            >
                              ACCEPT TRANSACTION
                            </button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              ))
            )}
          </div>

          <TransactionTotal />
        </div>
      </DashboardLayout>

      <dialog id="transaction_modal" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">ACCEPT TRANSACTION</h3>
        </div>

        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}

export default Transaction;
