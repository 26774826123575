import React from "react";

function DashboardFooter() {
  return (
    <>
      <footer className="mt-4 py-12 px-10">
        <hr className="mb-8" />
        <div className="w-full grid md:grid-cols-2 grid-cols-1 gap-14">
          <div>
            <p>+44 7529 724895</p>
            <p>info@fca-law.org</p>
            <p>
              © 2024 International Financial Claims and Compensation
              <br /> Authority
            </p>
            <p>Company House Registration Number: 15274505</p>
          </div>
          <div>
            <img
              src="/images/dashboard-footer-qu-code-img.png"
              alt=""
              className="w-full max-w-[220px]"
            />
          </div>
        </div>
      </footer>
    </>
  );
}

export default DashboardFooter;
