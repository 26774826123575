import React from "react";
import PrimaryLayout from "../components/layouts/primarylayout";

function Strategy() {
  return (
    <PrimaryLayout>
      <section className="container mx-auto my-14">
        <h2 className="mt-14 mb-2 text-3xl font-bold">
          Our Strategy and Business Plan
        </h2>
        <span className="inline-block w-14 h-1 bg-blue-600 mb-6"></span>

        <p className="my-4">
          Our strategy is predicated on a steadfast commitment to upholding the
          integrity of financial markets and protecting consumers from
          fraudulent practices. Through comprehensive oversight and rigorous
          enforcement, we aim to deter misconduct and ensure a fair,
          transparent, and secure market environment.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">
          Operational Objectives
        </h3>
        <p className="mb-2">
          To effectively combat fraud and protect consumers, our operational
          objectives include:
        </p>
        <ul className="md:pl-7 pl-0 list-disc">
          <li>
            Enhanced monitoring of financial activities to preemptively identify
            and address potential fraud.
          </li>
          <li>
            Rigorous vetting processes for financial institutions, ensuring
            compliance with stringent ethical standards.
          </li>
          <li>
            Empowering consumers through education on their rights and the
            mechanisms of financial protection available to them.
          </li>
        </ul>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">
          Engagement and Collaboration
        </h3>
        <p className="my-4">
          Recognizing the importance of collaboration in achieving our
          objectives, we actively engage with various stakeholders, including
          regulatory bodies, consumer advocacy groups, and the financial
          industry, to foster a united front against fraud.
        </p>

        <hr className="my-4" />
        <h3 className="text-2xl font-bold text-blue-500">Future Directions</h3>
        <p className="my-2">
          Looking forward, we are committed to continuous improvement and
          adaptation of our strategies to meet the evolving challenges of
          financial fraud, ensuring the security and confidence of consumers in
          the financial market.
        </p>
      </section>
    </PrimaryLayout>
  );
}

export default Strategy;
