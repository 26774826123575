import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Firms from "./pages/firms";
import Markets from "./pages/markets";
import Consumers from "./pages/consumers";
import Security from "./pages/security";
import Newsroom from "./pages/newsroom";
import Personal from "./pages/personal";
import Register from "./pages/auth/register";
import Login from "./pages/auth/login";
import Protect from "./pages/protect";
import MarketIntegrity from "./pages/marketintegrity";
import Competition from "./pages/competition";
import Strategy from "./pages/strategy";
import AnnualReport from "./pages/annualreport";
import SingleNews from "./pages/singlenews";
import Dashbaord from "./pages/dashboard";
import Transaction from "./pages/dashboard/transaction";
import Inbox from "./pages/dashboard/inbox";
import Profile from "./pages/dashboard/profile";
import Admin from "./pages/admin";
import AdminLogin from "./pages/auth/adminlogin";
import SingleUser from "./pages/admin/singleuser";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/firms" element={<Firms />} />
          <Route path="/markets" element={<Markets />} />
          <Route path="/consumers" element={<Consumers />} />
          <Route path="/security" element={<Security />} />
          <Route path="/newsroom" element={<Newsroom />} />
          <Route path="/personal" element={<Personal />} />

          <Route path="/protect" element={<Protect />} />
          <Route path="/market-integrity" element={<MarketIntegrity />} />
          <Route path="/competition" element={<Competition />} />
          <Route path="/strategy" element={<Strategy />} />
          <Route path="/annual-report" element={<AnnualReport />} />
          <Route path="/news/:id" element={<SingleNews />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          <Route path="/dashboard" element={<Dashbaord />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/user/:id" element={<SingleUser />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
