import React from "react";
import Header from "../common/header";
import Footer from "../common/footer";

function PrimaryLayout({ children }) {
  return (
    <>
      <Header></Header>
      <main>{children}</main>
      <Footer></Footer>
    </>
  );
}

export default PrimaryLayout;
