import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layouts/dashboardlayout";

function Profile() {
  const currentUser = JSON.parse(localStorage.getItem("dit-user"))[0];

  console.log("From Profile", currentUser);

  const [bank, setBank] = useState({
    bank_name: "",
    swift_code: "",
    iban: "",
    account_holder_name: "",
  });

  const getBank = async () => {
    const bank = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/bank/user/${currentUser.id}`
    );
    console.log(bank);

    if (bank.ok) {
      const bankRes = await bank.json();
      setBank(bankRes);
    }
  };

  useEffect(() => {
    getBank();
  }, []);

  return (
    <>
      <DashboardLayout>
        <div className="w-full max-w-2xl shadow bg-white border border-gray-400 py-14 px-10 rounded">
          <div className="flex items-center gap-3">
            <h2 className="text-xl font-semibold">Profile</h2>
          </div>
          <hr className="my-3" />

          <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
            <div>
              <p className="font-bold">Name:</p>
              <p>
                {currentUser.first_name} {currentUser.last_name}
              </p>
            </div>
            <div>
              <p className="font-bold">Surname:</p>
              <p>
                {currentUser.first_name} {currentUser.last_name}
              </p>
            </div>
            <div>
              <p className="font-bold">Email:</p>
              <p>{currentUser.email}</p>
            </div>
            <div>
              <p className="font-bold">Swift Code:</p>
              <p>{bank?.swift_code !== "" ? bank.swift_code : "N/A"}</p>
            </div>
            <div>
              <p className="font-bold">Bank Name:</p>
              <p>{bank?.bank_name !== "" ? bank.bank_name : "N/A"}</p>
            </div>
            <div>
              <p className="font-bold">Account Holder Name:</p>
              <p>
                {bank?.account_holder_name !== ""
                  ? bank.account_holder_name
                  : "N/A"}
              </p>
            </div>
            <div>
              <p className="font-bold">IBAN:</p>
              <p>{bank?.iban !== "" ? bank.iban : "N/A"}</p>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default Profile;
