import React, { useEffect } from "react";
import AdminHeader from "../common/adminheader";
import { useNavigate } from "react-router-dom";

function AdminLayout({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("dit-user"));
    if (!currentUser) {
      navigate("/adminlogin");
    } else if (currentUser.role !== "admin") {
      navigate("/adminlogin");
    }
  }, [navigate]);

  // localStorage.setItem(
  //   "dit-user",
  //   JSON.stringify({ id: 1, name: "Majed", role: "admin" })
  // );

  return (
    <main>
      <AdminHeader />
      {children}
    </main>
  );
}

export default AdminLayout;
