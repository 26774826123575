import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import AdminLayout from "../../components/layouts/adminlayout";
import {
  ArrowLeftRight,
  Building,
  Cake,
  DollarSign,
  Earth,
  Eye,
  EyeOff,
  FileArchive,
  FileUpIcon,
  Home,
  IdCard,
  Key,
  ListChecksIcon,
  ListOrderedIcon,
  Loader,
  Luggage,
  Mail,
  Pencil,
  Phone,
  PlusCircle,
  Trash,
  UserCircle2Icon,
} from "lucide-react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function SingleUser() {
  const { id } = useParams();

  const [user, setUser] = useState({});
  const [ditAccounts, setDitAccounts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [inboxMessages, setInboxMessages] = useState([]);
  const [bank, setBank] = useState({
    bank_name: "",
    swift_code: "",
    iban: "",
    account_holder_name: "",
  });
  const [accountStatus, setAccountStatus] = useState([]);
  const [selectedDitAc, setSelectedDitAc] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [selectedMessage, setSelectedMessage] = useState({});

  const getUser = async () => {
    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/users/${id}`
    );

    if (response.ok) {
      const users = await response.json();
      setUser(users);

      const acSt = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/users/status/${id}`
      );
      const acccountSt = await acSt.json();
      setAccountStatus(acccountSt);
      // acccountSt.map((dt) => console.log(dt));

      const inbMsg = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/inbox/user/${id}`
      );

      if (inbMsg.ok) {
        const inbMsgs = await inbMsg.json();
        setInboxMessages(inbMsgs);
      }

      const bank = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/bank/user/${id}`
      );
      console.log(bank);

      if (bank.ok) {
        const bankRes = await bank.json();
        setBank(bankRes);
      }

      const resp = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/dit/user/${id}`
      );
      if (resp.ok) {
        const ditAcc = await resp.json();
        setDitAccounts(ditAcc);

        const res = await fetch(
          `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/transaction/user/${id}`
        );
        if (res.ok) {
          const transactions = await res.json();
          setTransactions(transactions);
        }
      }
    }
  };

  useEffect(() => {
    getUser();
  }, [id]);

  const addDitAccount = async (e) => {
    e.preventDefault();
    const accountNumber = e.target.account_number.value;
    const date = e.target.date.value;
    const status = e.target.status.value;
    const amount = e.target.amount.value;

    if (accountNumber === "" || date === "" || status === "" || amount === "")
      return alert("All Field are required");

    const findDit = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/dit/byac/${accountNumber}`
    );
    const findDitRes = await findDit.json();
    if (findDitRes && findDitRes?.message !== "Dit not found")
      return alert("Change the account number");

    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/dit`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ accountNumber, date, status, amount, uid: id }),
      }
    );

    if (response.ok) {
      alert("DIT Account Added");
    } else {
      alert("Something went wrong");
    }
    //   Funtions will work with API
  };

  const addTransaction = async (e) => {
    e.preventDefault();
    const reference = e.target.reference.value;
    const companyName = e.target.company_name.value;
    const iban = e.target.iban.value;
    const accountNumber = e.target.ac_number.value;
    const amount = e.target.amount.value;
    const date = e.target.date.value;
    const bankBranch = e.target.bank_branch.value;
    const address = e.target.address.value;
    const status = e.target.status.value;

    if (
      reference === "" ||
      companyName === "" ||
      iban === "" ||
      accountNumber === "" ||
      amount === "" ||
      date === "" ||
      bankBranch === "" ||
      address === "" ||
      status === ""
    )
      return alert("All Field Are Required");

    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/transaction`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          reference,
          companyName,
          iban,
          accountNumber,
          amount,
          date,
          bankBranch,
          address,
          status,
          uid: id,
        }),
      }
    );

    if (response.ok) {
      alert("Transaction Added");
    } else {
      alert("Something went wrong");
    }
  };

  const addInboxMessage = async (e) => {
    e.preventDefault();

    const title = e.target.messageTitle.value;
    const description = e.target.messageDescription.value;
    const sendTime = e.target.sendTime.value;

    if (title === "" || description === "" || sendTime === "")
      return alert("All Field Are Required!");

    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/inbox`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          title,
          description,
          sendTime,
          uid: id,
          media: "",
        }),
      }
    );

    console.log(await response.json());

    if (response.ok) {
      alert("Inbox Message Added");
    } else {
      alert("Something went wrong");
    }
  };

  const generateAcNumber = (input) => {
    const inpt = document.getElementById(input);
    // Generate Number
    const nmb = uuidv4();
    inpt.value = nmb;

    if (input === "transaction-reference") {
      const nmb = uuidv4();
      const firstPart = "GB";
      const lastPart = "HK";
      // Extract digits from the UUID and format them
      const digits = nmb.replace(/\D/g, ""); // Remove non-digit characters
      const part2 = digits.substring(0, 3); // '6016'
      const part3 = digits.substring(3, 6); // '1331'
      inpt.value = `${firstPart}${part2}${part3}${lastPart}`;
    }

    if (input === "iban") {
      const nmb = uuidv4();
      const part1 = "GB54";
      const part2 = "HBUK";
      // Extract digits from the UUID and format them
      const digits = nmb.replace(/\D/g, ""); // Remove non-digit characters
      const part3 = digits.substring(0, 4); // '6016'
      const part4 = digits.substring(4, 8); // '1331'
      const part5 = digits.substring(8, 12); // '9268'
      const part6 = digits.substring(12, 14); // '19'
      inpt.value = `${part1} ${part2} ${part3} ${part4} ${part5} ${part6}`;
    }
  };

  const handlePredefinedMessge = (e) => {
    const messageNumber = parseInt(e.target.value);

    const messageArr = [
      {
        id: 0,
        title: "",
        description: "",
      },
      {
        id: 1,
        title: "Notification of Pending Transfer",
        description:
          "Your recent transfer is pending approval. Please check your account for more details.",
      },
      {
        id: 2,
        title: "Claim Update: Documents Required",
        description:
          "We have reviewed your recent claim and require additional documentation to proceed. Please upload the necessary documents at your earliest convenience.",
      },
      {
        id: 3,
        title: "Claim #12345: Review Completed",
        description:
          "Your claim has been reviewed by our specialists. We are pleased to inform you that your claim has been approved. Please check your email for further details regarding the compensation process.",
      },
      {
        id: 4,
        title: "Action Required: Confirm Your Bank Details",
        description:
          "Before we can process your compensation payment, we need you to confirm your bank account details. Please ensure that your details are up-to-date to avoid any delays in payment.",
      },
      {
        id: 5,
        title: "Notification of Pending Transfer",
        description: "Your recent transfer is pending approval.",
      },
      {
        id: 6,
        title: "Claim Update: Documents Required",
        description: "Claim Update: Documents Required",
      },
      {
        id: 7,
        title: "Pending Tax Compliance Verification",
        description:
          "Our records indicate that your account and pending transactions require tax compliance verification. To adhere to regulatory standards, a mandatory compliance fee must be processed. Please access your account dashboard to review the necessary steps for completing this verification.",
      },
    ];

    const findMessage = messageArr.find((msg) => msg.id === messageNumber);

    const messageTitle = document.getElementById("message-title");
    const messageDescription = document.getElementById("message-description");

    messageTitle.value = findMessage.title;
    messageDescription.value = findMessage.description;
  };

  const changeAccountStatus = async (e) => {
    e.preventDefault();
    const value = e.target.value;
    const updateUser = {
      ...user,
      status: value,
      firstName: user.first_name,
      lastName: user.last_name,
      accountType: user.account_type,
      postCode: user.post_code,
    };

    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/users/update/${id}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(updateUser),
      }
    );

    if (response.ok) {
      alert("Status Updated Successfully");
      window.location.href = `/user/${id}`;
    } else {
      alert("Something Went Wrong");
    }
  };

  const handleDitAccountDelete = async (acId) => {
    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/dit/delete/${acId}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response.ok) {
      alert("Status Updated Successfully");
      window.location.href = `/user/${id}`;
    } else {
      alert("Something Went Wrong");
    }
  };

  const handleTransactionDelete = async (trId) => {
    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/transaction/delete/${trId}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response.ok) {
      alert("Deleted Successfully");
      window.location.href = `/user/${id}`;
    } else {
      alert("Something Went Wrong");
    }
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    const fileInput = e.target.file;
    formData.append("file", fileInput.files[0]);

    try {
      const responsee = await fetch(
        "https://lawrecoveryportal.com/upload.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!responsee.ok) {
        const errorText = await responsee.text();
        throw new Error(`Upload failed: ${errorText}`);
      }

      const file = await responsee.json();
      const fileUrl = file.fileUrl;

      const response = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/inbox`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            title: "Send You A File",
            description: "Send You A File",
            sendTime: "",
            uid: id,
            media: fileUrl,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Status update failed: ${errorText}`);
      }

      console.log(await response.json());
      alert("Done");
    } catch (error) {
      console.error("Error:", error);
      alert(error.message);
    }
  };

  const handleInboxDelete = async (inboxId) => {
    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/inbox/delete/${inboxId}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response.ok) {
      alert("Deleted Successfully");
      window.location.href = `/user/${id}`;
    } else {
      alert("Something Went Wrong");
    }
  };

  const updateDitAccount = async (e) => {
    e.preventDefault();

    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/dit/update/${selectedDitAc.id}`,
      {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(selectedDitAc),
      }
    );

    if (response.ok) {
      // console.log(await response.json());
      window.location.href = `/user/${id}`;
    }
  };

  const updateTransaction = async (e, data = "") => {
    e.preventDefault();

    console.log(selectedTransaction);

    if (data) {
      const response = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/transaction/update/${data.id}`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        // console.log(await response.json());
        window.location.href = `/user/${id}`;
      }
    } else {
      const response = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/transaction/update/${selectedTransaction?.id}`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(selectedTransaction),
        }
      );

      if (response.ok) {
        // console.log(await response.json());
        window.location.href = `/user/${id}`;
      }
    }
  };

  const updateMessage = async (e) => {
    e.preventDefault();
    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/inbox/update/${selectedMessage.id}`,
      {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(selectedMessage),
      }
    );

    if (response.ok) {
      // console.log(await response.json());
      window.location.href = `/user/${id}`;
    }
  };

  return (
    <>
      <AdminLayout>
        <section className="my-10 container mx-auto">
          <div className="mb-10">
            <h2 className="text-center font-bold mb-4 text-2xl">
              User Dashboard
            </h2>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-10">
              <button
                className="btn btn-outline text-blue-500"
                onClick={() =>
                  document.getElementById("my_modal_2").showModal()
                }
              >
                <PlusCircle />
                <span>ADD DIT ACCOUNT</span>
              </button>
              <button
                className="btn btn-outline text-green-500"
                onClick={() =>
                  document.getElementById("my_modal_3").showModal()
                }
              >
                <PlusCircle />
                <span>ADD TRANSACTION</span>
              </button>
              <button
                className="btn btn-outline text-sky-400"
                onClick={() =>
                  document.getElementById("my_modal_4").showModal()
                }
              >
                <PlusCircle />
                <span>SEND INBOX MESSAGE</span>
              </button>
              <form
                className="flex gap-3"
                onSubmit={(e) => handleUploadFile(e)}
              >
                <label
                  className="btn bg-blue-600 text-white flex-1"
                  htmlFor="upload-pdf"
                >
                  <FileUpIcon />
                  <span>UPLOAD PDF</span>
                  <input type="file" name="file" id="upload-pdf" hidden />
                </label>
                <button className="btn bg-teal-600 text-white px-7">
                  Send
                </button>
              </form>
            </div>
            <div className="mx-auto mt-5 text-center">
              <select
                className="select select-bordered w-full max-w-xs"
                onChange={(e) => changeAccountStatus(e)}
                value={user?.status}
              >
                <option disabled selected>
                  -- Status --
                </option>
                <option value={"new"}>New</option>
                <option value={"pending"}>Pending</option>
                <option value={"ask-id-passport"}>ASK ID/PASSPORT</option>
                <option value={"ask-bank-details"}>ASK Bank Details</option>
                <option value={"ask-bank-statement"}>ASK Bank Statement</option>
                <option value={"show-dit-details"}>Show DIT Details</option>
                <option value={"confirm-bank-details"}>
                  Wait Confirm Bank Details
                </option>
                <option value={"A1"}>A1</option>
              </select>
            </div>

            <div className="my-10 grid md:grid-cols-2 grid-cols-1 gap-14">
              <div className="shadow border border-blue-500 rounded py-8 px-10">
                <h3 className="text-xl font-semibold">Personal Information</h3>
                <hr className="my-4" />
                <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
                  <div className="flex items-center gap-4">
                    <UserCircle2Icon />
                    <div>
                      <h3 className="text-lg font-semibold">
                        {user?.first_name} {user?.last_name}
                      </h3>
                      <span className="text-xs">{user?.address}</span>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <IdCard />
                    <div>
                      <h3 className="text-lg font-semibold">
                        Uploaded Passport ID
                      </h3>
                      <span className="text-xs flex items-center gap-2">
                        {accountStatus?.map((dt) =>
                          dt.status_type === "ask-id-passport" ? (
                            dt.is_done ? (
                              <>
                                Yes{" "}
                                <a
                                  href={dt.media}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Eye className="cursor-pointer" />
                                </a>
                              </>
                            ) : (
                              <>
                                No <EyeOff />
                              </>
                            )
                          ) : (
                            ""
                          )
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <Cake />
                    <div>
                      <h3 className="text-lg font-semibold">Date of Birth</h3>
                      <span className="text-xs flex items-center gap-2">
                        {user?.day} {user?.month} {user?.year}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <ListChecksIcon />
                    <div>
                      <h3 className="text-lg font-semibold">
                        Uploaded Bank Statement
                      </h3>
                      <span className="text-xs flex items-center gap-2">
                        {accountStatus?.map((dt) =>
                          dt.status_type === "ask-bank-statement" ? (
                            dt.is_done ? (
                              <>
                                Yes{" "}
                                <a
                                  href={dt.media}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Eye className="cursor-pointer" />
                                </a>
                              </>
                            ) : (
                              <>
                                No <EyeOff />
                              </>
                            )
                          ) : (
                            ""
                          )
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <Luggage />
                    <div>
                      <h3 className="text-lg font-semibold">Role</h3>
                      <span className="text-xs flex items-center gap-2">
                        {user?.role}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <Key />
                    <div>
                      <h3 className="text-lg font-semibold">PWD</h3>
                      <span className="text-xs flex items-center gap-2">
                        {user?.password}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="shadow border border-blue-500 rounded py-8 px-10">
                <h3 className="text-xl font-semibold">Contact Information</h3>
                <hr className="my-4" />
                <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
                  <div className="flex items-center gap-4">
                    <Mail />
                    <div>
                      <h3 className="text-lg font-semibold">Email</h3>
                      <span className="text-xs">{user?.email}</span>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <Earth />
                    <div>
                      <h3 className="text-lg font-semibold">Country</h3>
                      <span className="text-xs flex items-center gap-2">
                        {user?.country}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <Phone />
                    <div>
                      <h3 className="text-lg font-semibold">Phone</h3>
                      <span className="text-xs flex items-center gap-2">
                        {user?.mobile}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-4"></div>
                  <div className="flex items-center gap-4">
                    <Home />
                    <div>
                      <h3 className="text-lg font-semibold">Address</h3>
                      <span className="text-xs flex items-center gap-2">
                        {user?.address}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-4"></div>
                </div>
              </div>
            </div>

            <div className="shadow border border-blue-500 rounded py-8 px-10 mb-10">
              <h3 className="text-xl font-semibold">Banking Details</h3>
              <hr className="my-4" />
              <div className="grid grid-cols-1 gap-3">
                <div className="flex items-center gap-4">
                  <Building />
                  <div>
                    <h3 className="text-lg font-semibold">Bank Name</h3>
                    <span className="text-xs">
                      {bank?.bank_name !== "" ? bank.bank_name : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <ArrowLeftRight />
                  <div>
                    <h3 className="text-lg font-semibold">SWIFT Code</h3>
                    <span className="text-xs flex items-center gap-2">
                      {bank?.swift_code !== "" ? bank.swift_code : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <ListOrderedIcon />
                  <div>
                    <h3 className="text-lg font-semibold">IBAN</h3>
                    <span className="text-xs flex items-center gap-2">
                      {bank?.iban !== "" ? bank.iban : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <UserCircle2Icon />
                  <div>
                    <h3 className="text-lg font-semibold">
                      Account Holder Name
                    </h3>
                    <span className="text-xs flex items-center gap-2">
                      {bank?.account_holder_name !== ""
                        ? bank.account_holder_name
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="shadow border border-blue-500 rounded py-8 px-10 mb-10">
              <h3 className="text-xl font-semibold">User Accounts</h3>
              <hr className="my-4" />
              <div className="overflow-x-auto">
                <table className="table">
                  {/* head */}
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Account Number</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* row 1 */}
                    {ditAccounts.length > 0 ? (
                      ditAccounts?.map((ac) => (
                        <tr>
                          <th>{ac?.id}</th>
                          <td>{ac?.account_number}</td>
                          <td>{ac?.date}</td>
                          <td>${ac?.amount}</td>
                          <td
                            className={`font-bold ${
                              ac?.status === "active"
                                ? "text-green-600"
                                : "text-yellow-600"
                            }`}
                          >
                            {ac?.status}
                          </td>
                          <td>
                            <div className="flex items-center gap-3">
                              <Pencil
                                className="text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setSelectedDitAc(ac);
                                  document
                                    .getElementById("dit_account_update_modal")
                                    .showModal();
                                }}
                              />
                              <Trash
                                className="text-red-600 cursor-pointer"
                                onClick={() => handleDitAccountDelete(ac?.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="shadow border border-blue-500 rounded py-8 px-10 mb-10">
              <h3 className="text-xl font-semibold">User Transactions</h3>
              <hr className="my-4" />
              <div className="overflow-x-auto">
                <table className="table">
                  {/* head */}
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Reference</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Withdrawal Allowed</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* row 1 */}
                    {transactions.length > 0 ? (
                      transactions?.map((tr) => (
                        <tr>
                          <th>{tr?.id}</th>
                          <td>{tr?.reference}</td>
                          <td>{tr?.date}</td>
                          <td>${tr?.amount}</td>
                          <td
                            className={`font-bold ${
                              tr?.status === "pending"
                                ? "text-yellow-600"
                                : tr?.status === "completed"
                                ? "text-green-600"
                                : "text-red-600"
                            }`}
                          >
                            {tr?.status}
                          </td>
                          <td>
                            <div className="form-control w-20">
                              <label
                                className="label cursor-pointer"
                                htmlFor="withdrawal"
                              >
                                <span className="label-text"></span>
                                <input
                                  type="checkbox"
                                  id="withdrawal"
                                  className="toggle toggle-primary"
                                  defaultChecked={tr.withdrawal}
                                  onChange={async (e) => {
                                    await updateTransaction(e, {
                                      ...tr,
                                      withdrawal: e.target.checked,
                                    });
                                  }}
                                />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="flex items-center gap-3">
                              <Pencil
                                className="text-blue-500 cursor-pointer"
                                onClick={async () => {
                                  await setSelectedTransaction(tr);
                                  await document
                                    .getElementById("transaction_update_modal")
                                    .showModal();
                                }}
                              />
                              <Trash
                                className="text-red-600 cursor-pointer"
                                onClick={() => handleTransactionDelete(tr?.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="shadow border border-blue-500 rounded py-8 px-10 mb-10">
              <h3 className="text-xl font-semibold">Inbox Messages</h3>
              <hr className="my-4" />
              <div className="overflow-x-auto">
                <table className="table">
                  {/* head */}
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Send Time</th>
                      <th>Media</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* row 1 */}
                    {inboxMessages.length > 0 ? (
                      inboxMessages?.map((ibx) => (
                        <tr>
                          <th>{ibx?.id}</th>
                          <td>{ibx?.title}</td>
                          <td>{ibx?.description}</td>
                          <td>
                            <a
                              href={`${ibx?.media}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FileArchive />
                            </a>
                          </td>
                          <td>
                            <div className="flex items-center gap-3">
                              <Pencil
                                className="text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setSelectedMessage(ibx);
                                  document
                                    .getElementById("message_update_modal")
                                    .showModal();
                                }}
                              />
                              <Trash
                                className="text-red-600 cursor-pointer"
                                onClick={() => handleInboxDelete(ibx?.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </AdminLayout>

      {/* ============= DIT Account Modal ============= */}
      <dialog id="my_modal_2" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Add New Account</h3>
          <form action="" onSubmit={(e) => addDitAccount(e)}>
            <div className="my-4">
              <label htmlFor="">Account Number</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400 pr-3">
                <input
                  type="text"
                  className="flex-1 input"
                  id="account_number"
                  name="account_number"
                />
                <Loader
                  className="cursor-pointer"
                  onClick={() => generateAcNumber("account_number")}
                />
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="">Date</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400">
                <input
                  type="date"
                  className="flex-1 input"
                  name="date"
                  min={"2000-01-01"}
                  max={"2024-12-31"}
                />
                {/* <Calendar className="cursor-pointer" /> */}
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="">Status</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400 pr-3">
                <select className="select select-bordered w-full" name="status">
                  <option disabled selected>
                    -- Status --
                  </option>
                  <option value={"active"}>Active</option>
                  <option value={"withdraw"}>Withdraw</option>
                </select>
                {/* <Calendar className="cursor-pointer" /> */}
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="">Amount</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400 pr-3">
                <input type="number" className="flex-1 input" name="amount" />
              </div>
            </div>
            <div className="my-4">
              <button className="btn bg-blue-600 w-full text-white">
                SUBMIT ACCOUNT
              </button>
            </div>
          </form>
        </div>

        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      {/* ============= DIT Account Modal ============= */}

      {/* ============= Add Transaction Modal ============= */}
      <dialog id="my_modal_3" className="modal">
        <div className="modal-box w-11/12 max-w-5xl">
          <h3 className="font-bold text-lg">Add New Transaction</h3>
          <form action="" onSubmit={(e) => addTransaction(e)}>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
              <div>
                <div className="my-4">
                  <label htmlFor="">Transaction Reference</label>
                  <div className="flex items-center justify-between gap-3 border border-gray-400 pr-3 rounded">
                    <input
                      type="text"
                      className="input w-full"
                      name="reference"
                      id="transaction-reference"
                    />
                    <Loader
                      className="cursor-pointer"
                      onClick={() => generateAcNumber("transaction-reference")}
                    />
                  </div>
                </div>
                <div className="my-4">
                  <label htmlFor="">Sender/ Company name</label>
                  <div className="flex items-center justify-between gap-3 border border-gray-400 rounded">
                    <input
                      type="text"
                      className="input w-full"
                      name="company_name"
                    />
                  </div>
                </div>
                <div className="my-4">
                  <label htmlFor="">Sender IBAN</label>
                  <div className="flex items-center justify-between gap-3 border border-gray-400 pr-3 rounded">
                    <input
                      type="text"
                      className="input w-full"
                      name="iban"
                      id="iban"
                    />
                    <Loader
                      className="cursor-pointer"
                      onClick={() => generateAcNumber("iban")}
                    />
                  </div>
                </div>
                <div className="my-4">
                  <label htmlFor="">Account Number</label>
                  <div className="flex items-center justify-between gap-3 border border-gray-400 pr-3 rounded">
                    <input
                      type="text"
                      className="input w-full"
                      name="ac_number"
                      id="ac_number"
                    />
                    <Loader
                      className="cursor-pointer"
                      onClick={() => generateAcNumber("ac_number")}
                    />
                  </div>
                </div>
                <div className="my-4">
                  <button className="btn w-full bg-blue-600 text-white mt-6">
                    SUBMIT TRANSACTION
                  </button>
                </div>
              </div>
              <div>
                <div className="my-4">
                  <label htmlFor="">Amount</label>
                  <div className="flex items-center justify-between gap-3 border border-gray-400 pl-2 rounded">
                    <DollarSign />
                    <input type="text" className="input w-full" name="amount" />
                  </div>
                </div>
                <div className="my-4">
                  <label htmlFor="">Date</label>
                  <div className="flex items-center justify-between gap-3 border border-gray-400 rounded">
                    <input type="date" className="input w-full" name="date" />
                  </div>
                </div>
                <div className="my-4">
                  <label htmlFor="">Sender Bank Branch</label>
                  <div className="flex items-center justify-between gap-3 border border-gray-400 rounded">
                    <input
                      type="text"
                      className="input w-full"
                      name="bank_branch"
                    />
                  </div>
                </div>
                <div className="my-4">
                  <label htmlFor="">Sender Address</label>
                  <div className="flex items-center justify-between gap-3 border border-gray-400 rounded">
                    <input
                      type="text"
                      className="input w-full"
                      name="address"
                    />
                  </div>
                </div>
                <div className="my-4">
                  <label htmlFor="" className="block">
                    Status
                  </label>
                  <select
                    className="select select-bordered w-full"
                    name="status"
                  >
                    <option disabled selected>
                      -- Status --
                    </option>
                    <option value={"pending"}>Pending</option>
                    <option value={"compeleted"}>Completed</option>
                    <option value={"cancelled"}>Cancelled</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>

        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      {/* ============= Add Transaction Modal ============= */}

      {/* ============= Add Transaction Modal ============= */}
      <dialog id="my_modal_4" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Send Inbox Message</h3>
          <form action="" onSubmit={(e) => addInboxMessage(e)}>
            <div className="my-4">
              <label htmlFor="">Predefined Message</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400 pr-3">
                <select
                  className="select select-bordered w-full"
                  onChange={(e) => handlePredefinedMessge(e)}
                >
                  <option value={0}>None</option>
                  <option value={1}>Notification of Pending Transfer</option>
                  <option value={2}>Claim Update: Documents Required</option>
                  <option value={3}>Claim #12345: Review Completed</option>
                  <option value={4}>
                    Action Required: Confirm Your Bank Details
                  </option>
                  <option value={5}>Notification of Pending Transfer</option>
                  <option value={6}>Claim Update: Documents Required</option>
                  <option value={7}>Pending Tax Compliance Verification</option>
                </select>
                {/* <Calendar className="cursor-pointer" /> */}
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="">Message Title</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400 pr-3">
                <input
                  type="text"
                  className="flex-1 input"
                  name="messageTitle"
                  id="message-title"
                />
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="">Message Description</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400">
                <textarea
                  className="textarea textarea-bordered w-full"
                  placeholder="Bio"
                  name="messageDescription"
                  id="message-description"
                ></textarea>
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="">Send Time</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400">
                <input type="date" className="flex-1 input" name="sendTime" />
                {/* <Calendar className="cursor-pointer" /> */}
              </div>
            </div>
            <div className="my-4">
              <button className="btn bg-blue-600 w-full text-white">
                SEND MESSAGE
              </button>
            </div>
          </form>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      {/* ============= Add Transaction Modal ============= */}

      {/* ============= DIT Account Update Modal ============= */}
      <dialog id="dit_account_update_modal" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Update Account</h3>
          <form action="" onSubmit={(e) => updateDitAccount(e)}>
            <div className="my-4">
              <label htmlFor="">Account Number</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400 pr-3">
                <input
                  type="text"
                  className="flex-1 input"
                  id="account_number"
                  name="account_number"
                  disabled
                  value={selectedDitAc.account_number}
                />
                <Loader
                  className="cursor-pointer"
                  onClick={() => generateAcNumber("account_number")}
                />
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="">Date</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400">
                <input
                  type="date"
                  className="flex-1 input"
                  name="date"
                  min={"2000-01-01"}
                  max={"2024-12-31"}
                  disabled
                  value={selectedDitAc.date}
                />
                {/* <Calendar className="cursor-pointer" /> */}
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="">Status</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400 pr-3">
                <select
                  className="select select-bordered w-full"
                  name="status"
                  value={selectedDitAc.status}
                  onChange={(e) =>
                    setSelectedDitAc({
                      ...selectedDitAc,
                      [e.target.name]: e.target.value,
                    })
                  }
                >
                  <option disabled selected>
                    -- Status --
                  </option>
                  <option value={"active"}>Active</option>
                  <option value={"withdraw"}>Withdraw</option>
                </select>
                {/* <Calendar className="cursor-pointer" /> */}
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="">Amount</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400 pr-3">
                <input
                  type="number"
                  className="flex-1 input"
                  name="amount"
                  value={selectedDitAc.amount}
                  onChange={(e) =>
                    setSelectedDitAc({
                      ...selectedDitAc,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-4">
              <button className="btn bg-blue-600 w-full text-white">
                UPDATE ACCOUNT
              </button>
            </div>
          </form>
        </div>

        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      {/* ============= DIT Account Update Modal ============= */}

      {/* ============= Transaction Update Modal ============= */}
      <dialog id="transaction_update_modal" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Update Transaction</h3>
          <form action="" onSubmit={(e) => updateTransaction(e)}>
            <div className="my-4">
              <label htmlFor="">Amount</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400">
                <input
                  type="text"
                  className="flex-1 input"
                  id="reference"
                  name="reference"
                  disabled
                  value={selectedTransaction.reference}
                />
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="">Amount</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400">
                <input
                  type="text"
                  className="flex-1 input"
                  id="amount"
                  name="amount"
                  disabled
                  value={selectedTransaction.amount}
                />
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="">Status</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400">
                <select
                  className="select select-bordered w-full"
                  name="status"
                  value={selectedTransaction.status}
                  onChange={(e) =>
                    setSelectedTransaction({
                      ...selectedTransaction,
                      [e.target.name]: e.target.value,
                    })
                  }
                >
                  <option disabled selected>
                    -- Status --
                  </option>
                  <option value={"completed"}>Completed</option>
                  <option value={"pending"}>Pending</option>
                  <option value={"cancelled"}>Cancelled</option>
                </select>
                {/* <Calendar className="cursor-pointer" /> */}
              </div>
            </div>
            <div className="my-4">
              <button className="btn bg-blue-600 w-full text-white">
                UPDATE TRANSACTION
              </button>
            </div>
          </form>
        </div>

        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      {/* ============= Transaction Update Modal ============= */}

      {/* ============= Message Update Modal ============= */}
      <dialog id="message_update_modal" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Update Transaction</h3>
          <form action="" onSubmit={(e) => updateMessage(e)}>
            <div className="my-4">
              <label htmlFor="">Title</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400">
                <input
                  type="text"
                  className="flex-1 input"
                  id="title"
                  name="title"
                  value={selectedMessage.title}
                  onChange={(e) =>
                    setSelectedMessage({
                      ...selectedMessage,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="">Description</label>
              <div className="flex items-center justify-between gap-4 border border-gray-400">
                <input
                  type="text"
                  className="flex-1 input"
                  id="description"
                  name="description"
                  value={selectedMessage.description}
                  onChange={(e) =>
                    setSelectedMessage({
                      ...selectedMessage,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-4">
              <button className="btn bg-blue-600 w-full text-white">
                UPDATE MESSAGE
              </button>
            </div>
          </form>
        </div>

        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      {/* ============= Message Update Modal ============= */}
    </>
  );
}

export default SingleUser;
