import React, { useEffect, useState } from "react";
import PrimaryLayout from "../../components/layouts/primarylayout";
import { Link, useNavigate } from "react-router-dom";
import { ArrowUpRightFromSquareIcon } from "lucide-react";

function Register() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [accountType, setAccountType] = useState("");
  const [haveUkAddress, setHaveUkAddress] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    day: 0,
    month: "",
    year: 0,
    country: "",
    address: "",
    postCode: 0,
    password: "",
    confirmPassword: "",
    agreeTOS: true,
  });

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const checkStep3Inputs = async () => {
    const {
      title,
      firstName,
      lastName,
      email,
      mobile,
      day,
      month,
      year,
      postCode,
      password,
      confirmPassword,
      agreeTOS,
    } = formData;
    if (
      title === "" ||
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      mobile === "" ||
      day === "" ||
      month === "" ||
      year === "" ||
      postCode === "" ||
      password === "" ||
      confirmPassword === "" ||
      agreeTOS === ""
    ) {
      alert("All Field Are Required!");
    } else if (password !== confirmPassword) {
      alert("Password and confirm Password are not match");
    } else {
      setIsLoading(true);
      const addUser = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/users`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            accountType,
            status: "new",
            role: "user",
          }),
        }
      );

      const userData = await addUser.json();
      console.log(userData);

      if (addUser.ok) {
        setIsLoading(false);
        localStorage.setItem("dit-user", JSON.stringify(userData));
        navigate("/dashboard");
      } else {
        alert("Something went wrong to create user");
        setIsLoading(false);
      }
    }

    // setStep(4);
  };

  return (
    <>
      <PrimaryLayout>
        <section className="container mx-auto my-10">
          <div className="mx-auto w-full max-w-xl shadow-xl py-10 px-7 rounded border border-blue-200">
            {step === 1 && (
              <>
                <div>
                  <h2 className="md:text-3xl text-xl font-bold text-blue-500">
                    Digital Integrity Trust (DIT) Case Registration
                  </h2>
                  <p className="my-5">
                    Begin the process of securing your case with our online
                    management portal. This is the first step towards reclaiming
                    control over fraudulently used accounts and assets.
                  </p>

                  <div className="my-6 py-6 px-8 rounded bg-red-50">
                    <p className="text-sm">
                      Registration processing time is approximately 6 working
                      days. Activation of certain functionalities may vary based
                      on case complexity.
                    </p>
                  </div>

                  <div className="my-10">
                    <h3 className="text-xl font-semibold">
                      Registration Requirements
                    </h3>
                    <ul className="md:pl-5 pl-0 flex flex-col gap-6 mt-4">
                      <li className="flex items-center gap-5">
                        <img
                          src="/images/check-icon.png"
                          alt=""
                          className="w-full max-w-[25px]"
                        />
                        <span>Full legal name</span>
                      </li>
                      <li className="flex items-center gap-5">
                        <img
                          src="/images/check-icon.png"
                          alt=""
                          className="w-full max-w-[25px]"
                        />
                        <span>Address and postal code</span>
                      </li>
                      <li className="flex items-center gap-5">
                        <img
                          src="/images/check-icon.png"
                          alt=""
                          className="w-full max-w-[25px]"
                        />
                        <span>Valid email address</span>
                      </li>
                      <li className="flex items-center gap-5">
                        <img
                          src="/images/check-icon.png"
                          alt=""
                          className="w-full max-w-[25px]"
                        />
                        <span>Mobile phone number</span>
                      </li>
                      <li className="flex items-center gap-5">
                        <img
                          src="/images/check-icon.png"
                          alt=""
                          className="w-full max-w-[25px]"
                        />
                        <span>Detailed case information</span>
                      </li>
                    </ul>
                  </div>

                  <div className="my-7 bg-white py-7 px-8 border-l-4 border-l-blue-500 flex items-center gap-4">
                    <p className="flex items-start gap-3">
                      <img
                        src="/images/info-icon.png"
                        alt=""
                        className="w-full max-w-[25px]"
                      />
                      <span>
                        Be prepared to provide detailed information regarding
                        your case for a thorough investigation and case
                        management.
                      </span>
                    </p>
                  </div>

                  <div className="my-12">
                    <h3 className="text-xl font-semibold">Next Steps</h3>
                    <div className="mt-5">
                      <div className="flex items-center justify-between gap-7">
                        <div className="flex items-center gap-7">
                          <span className="block w-4 h-4 rounded-full bg-blue-600"></span>
                          <Link
                            to={"/login"}
                            className="text-blue-500 underline"
                          >
                            Already registered?
                          </Link>
                        </div>
                        <span>
                          <ArrowUpRightFromSquareIcon className="text-blue-500" />
                        </span>
                      </div>
                      <div className="flex items-center justify-between gap-7 mt-4">
                        <div className="flex items-center gap-7">
                          <span className="block w-4 h-4 rounded-full bg-blue-600"></span>
                          <button
                            to={"/login"}
                            className="text-blue-500 underline flex items-center gap-4"
                            onClick={() => setStep(2)}
                          >
                            Register{" "}
                            <ArrowUpRightFromSquareIcon className="text-blue-500" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p>
                    Leverage our sophisticated portal to actively manage and
                    monitor your case progress after successful registration.
                  </p>

                  <div className="my-7 bg-white py-7 px-8 border-l-4 border-l-blue-500 flex items-center gap-4 mt-6">
                    <p className="flex items-start gap-3">
                      <img
                        src="/images/info-icon.png"
                        alt=""
                        className="w-full max-w-[25px]"
                      />
                      <span>
                        Proceeding with the registration indicates your
                        acceptance of our Terms of Service and Privacy Policy.
                        We are committed to safeguarding your data and privacy.
                      </span>
                    </p>
                  </div>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <div>
                  <h3 className="text-xl font-semibold text-gray-400">
                    Step 1 of 6: Getting started
                  </h3>
                  <h2 className="text-2xl font-semibold my-3">
                    Your path to secure case management
                  </h2>

                  <p className="mt-4 mb-3">
                    Registering your case with DIT ensures you have secure and
                    confidential access to our financial integrity systems.
                    We're dedicated to safeguarding your data as we navigate
                    through your case.
                  </p>
                  <p>
                    Start by selecting the type of account you wish to register.
                    This helps us tailor our services to your specific needs and
                    provide the most efficient support.
                  </p>

                  <select
                    className="select select-bordered w-full mt-5"
                    onChange={(e) => setAccountType(e.target.value)}
                  >
                    <option disabled selected>
                      -- Choose Account Type --
                    </option>
                    <option value={"consumer-account"}>Consumer Account</option>
                    <option value={"trading-account"}>Trading Account</option>
                    <option value={"business-account"}>Business Account</option>
                  </select>

                  <div className="my-5 py-7 px-5 border-l-4 border-l-blue-500">
                    <p className="mb-3">
                      By proceeding, you agree to our terms and confirm that you
                      have read our Data Protection Policy. DIT commits to the
                      ethical handling of your personal information throughout
                      your case's lifecycle.
                    </p>
                    <p>
                      Have questions? Learn more about{" "}
                      <Link to={"/"} className="text-blue-500">
                        how DIT handles cases
                      </Link>{" "}
                      and the protections in place for you.
                    </p>
                  </div>

                  <button
                    className="btn bg-blue-500 text-white"
                    onClick={() => {
                      accountType === ""
                        ? alert("Please choose account type")
                        : setStep(3);
                    }}
                  >
                    CONTINUE
                  </button>
                </div>
              </>
            )}
            {step === 3 && (
              <>
                <div>
                  <div className="mb-7">
                    <h3 className="text-xl font-semibold text-gray-400">
                      Step 2 of 6: Personal Details
                    </h3>
                    <h3 className="text-lg mb-2">About You</h3>
                    <select
                      className="select select-bordered w-full mb-5 block"
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData({ ...formData, title: value });
                      }}
                    >
                      <option disabled selected>
                        -- Title --
                      </option>
                      <option value={"mr"}>Mr.</option>
                      <option value={"ms"}>Ms.</option>
                      <option value={"mrs"}>Mrs.</option>
                      <option value={"dr"}>Dr.</option>
                    </select>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="input input-bordered w-full mb-5 block"
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData({ ...formData, firstName: value });
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="input input-bordered w-full mb-5 block"
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData({ ...formData, lastName: value });
                      }}
                    />
                    <label className="input input-bordered flex items-center gap-2 mb-5 block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="h-4 w-4 opacity-70"
                      >
                        <path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                        <path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                      </svg>
                      <input
                        type="text"
                        className="grow"
                        placeholder="Email"
                        onChange={(e) => {
                          const value = e.target.value;
                          setFormData({ ...formData, email: value });
                        }}
                      />
                    </label>
                    <input
                      type="text"
                      placeholder="Mobile"
                      className="input input-bordered w-full mb-5 block"
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData({ ...formData, mobile: value });
                      }}
                    />
                    <div className="grid md:grid-cols-7 grid-cols-1 gap-6">
                      <select
                        className="select select-bordered w-full col-span-2"
                        onChange={(e) => {
                          const value = e.target.value;
                          setFormData({ ...formData, day: value });
                        }}
                      >
                        <option disabled selected>
                          -- Day? --
                        </option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                        <option value={13}>13</option>
                        <option value={14}>14</option>
                        <option value={15}>15</option>
                        <option value={16}>16</option>
                        <option value={17}>17</option>
                        <option value={18}>18</option>
                        <option value={19}>19</option>
                        <option value={20}>20</option>
                        <option value={21}>21</option>
                        <option value={22}>22</option>
                        <option value={23}>23</option>
                        <option value={24}>24</option>
                        <option value={25}>25</option>
                        <option value={26}>26</option>
                        <option value={27}>27</option>
                        <option value={28}>28</option>
                        <option value={29}>29</option>
                        <option value={30}>30</option>
                        <option value={31}>31</option>
                      </select>
                      <select
                        className="select select-bordered w-full col-span-3"
                        onChange={(e) => {
                          const value = e.target.value;
                          setFormData({ ...formData, month: value });
                        }}
                      >
                        <option disabled selected>
                          -- Month --
                        </option>
                        <option value={"january"}>January</option>
                        <option value={"february"}>February</option>
                        <option value={"march"}>March</option>
                        <option value={"ppril"}>April</option>
                        <option value={"may"}>May</option>
                        <option value={"june"}>June</option>
                        <option value={"july"}>July</option>
                        <option value={"august"}>August</option>
                        <option value={"september"}>September</option>
                        <option value={"october"}>October</option>
                        <option value={"november"}>November</option>
                        <option value={"december"}>December</option>
                      </select>
                      <select
                        className="select select-bordered w-full col-span-2"
                        onChange={(e) => {
                          const value = e.target.value;
                          setFormData({ ...formData, year: value });
                        }}
                      >
                        <option disabled selected>
                          -- Year --
                        </option>
                        <option value={2024}>2024</option>
                        <option value={2023}>2023</option>
                        <option value={2022}>2022</option>
                        <option value={2021}>2021</option>
                        <option value={2020}>2020</option>
                        <option value={2019}>2019</option>
                        <option value={2018}>2018</option>
                        <option value={2017}>2017</option>
                        <option value={2016}>2016</option>
                        <option value={2015}>2015</option>
                        <option value={2014}>2014</option>
                        <option value={2013}>2013</option>
                        <option value={2012}>2012</option>
                        <option value={2011}>2011</option>
                        <option value={2010}>2010</option>
                        <option value={2009}>2009</option>
                        <option value={2008}>2008</option>
                        <option value={2007}>2007</option>
                        <option value={2006}>2006</option>
                        <option value={2005}>2005</option>
                        <option value={2004}>2004</option>
                        <option value={2003}>2003</option>
                        <option value={2002}>2002</option>
                        <option value={2001}>2001</option>
                        <option value={2000}>2000</option>
                        <option value={1999}>1999</option>
                        <option value={1998}>1998</option>
                        <option value={1997}>1997</option>
                        <option value={1996}>1996</option>
                        <option value={1995}>1995</option>
                        <option value={1994}>1994</option>
                        <option value={1993}>1993</option>
                        <option value={1992}>1992</option>
                        <option value={1991}>1991</option>
                        <option value={1990}>1990</option>
                        <option value={1989}>1989</option>
                        <option value={1988}>1988</option>
                        <option value={1987}>1987</option>
                        <option value={1986}>1986</option>
                        <option value={1985}>1985</option>
                        <option value={1984}>1984</option>
                        <option value={1983}>1983</option>
                        <option value={1982}>1982</option>
                        <option value={1981}>1981</option>
                        <option value={1980}>1980</option>
                        <option value={1979}>1979</option>
                        <option value={1978}>1978</option>
                        <option value={1977}>1977</option>
                        <option value={1976}>1976</option>
                        <option value={1975}>1975</option>
                        <option value={1974}>1974</option>
                        <option value={1973}>1973</option>
                        <option value={1972}>1972</option>
                        <option value={1971}>1971</option>
                        <option value={1970}>1970</option>
                        <option value={1969}>1969</option>
                        <option value={1968}>1968</option>
                        <option value={1967}>1967</option>
                        <option value={1966}>1966</option>
                        <option value={1965}>1965</option>
                        <option value={1964}>1964</option>
                        <option value={1963}>1963</option>
                        <option value={1962}>1962</option>
                        <option value={1961}>1961</option>
                        <option value={1960}>1960</option>
                        <option value={1959}>1959</option>
                        <option value={1958}>1958</option>
                        <option value={1957}>1957</option>
                        <option value={1956}>1956</option>
                        <option value={1955}>1955</option>
                        <option value={1954}>1954</option>
                        <option value={1953}>1953</option>
                        <option value={1952}>1952</option>
                        <option value={1951}>1951</option>
                        <option value={1950}>1950</option>
                        <option value={1949}>1949</option>
                        <option value={1948}>1948</option>
                        <option value={1947}>1947</option>
                        <option value={1946}>1946</option>
                        <option value={1945}>1945</option>
                        <option value={1944}>1944</option>
                        <option value={1943}>1943</option>
                        <option value={1942}>1942</option>
                        <option value={1941}>1941</option>
                        <option value={1940}>1940</option>
                        <option value={1939}>1939</option>
                        <option value={1938}>1938</option>
                        <option value={1937}>1937</option>
                        <option value={1936}>1936</option>
                        <option value={1935}>1935</option>
                        <option value={1934}>1934</option>
                        <option value={1933}>1933</option>
                        <option value={1932}>1932</option>
                        <option value={1931}>1931</option>
                        <option value={1930}>1930</option>
                        <option value={1929}>1929</option>
                        <option value={1928}>1928</option>
                        <option value={1927}>1927</option>
                        <option value={1926}>1926</option>
                        <option value={1925}>1925</option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-7">
                    <h3 className="text-lg mb-2">Address</h3>
                    <div className="my-4 grid grid-cols-2">
                      <button
                        className={`btn rounded-none ${
                          haveUkAddress
                            ? "border-gray-600 border"
                            : "bg-blue-600 text-white"
                        }`}
                        onClick={() => {
                          setHaveUkAddress(!haveUkAddress);
                        }}
                      >
                        I have an overseas address
                      </button>
                      <button
                        className={`btn rounded-none ${
                          haveUkAddress
                            ? "bg-blue-600 text-white"
                            : "border-gray-600 border"
                        }`}
                        onClick={() => {
                          setHaveUkAddress(!haveUkAddress);
                        }}
                      >
                        I have an overseas address
                      </button>
                    </div>
                    {!haveUkAddress && (
                      <>
                        <div>
                          <select
                            className="select select-bordered w-full block mb-5"
                            onChange={(e) => {
                              const value = e.target.value;
                              setFormData({ ...formData, country: value });
                            }}
                          >
                            <option disabled selected>
                              -- Country --
                            </option>
                            <option value={"Afghanistan"}>Afghanistan</option>
                            <option value={"Åland Islands"}>
                              Åland Islands
                            </option>
                            <option value={"Albania"}>Albania</option>
                            <option value={"Algeria"}>Algeria</option>
                            <option value={"American Samoa"}>
                              American Samoa
                            </option>
                            <option value={"AndorrA"}>AndorrA</option>
                            <option value={"Angola"}>Angola</option>
                            <option value={"Anguilla"}>Anguilla</option>
                            <option value={"Antarctica"}>Antarctica</option>
                            <option value={"Antigua and Barbuda"}>
                              Antigua and Barbuda
                            </option>
                            <option value={"Argentina"}>Argentina</option>
                            <option value={"Armenia"}>Armenia</option>
                            <option value={"Aruba"}>Aruba</option>
                            <option value={"Australia"}>Australia</option>
                            <option value={"Austria"}>Austria</option>
                            <option value={"Azerbaijan"}>Azerbaijan</option>
                            <option value={"Bahamas"}>Bahamas</option>
                            <option value={"Bahrain"}>Bahrain</option>
                            <option value={"Bangladesh"}>Bangladesh</option>
                            <option value={"Barbados"}>Barbados</option>
                            <option value={"Belarus"}>Belarus</option>
                            <option value={"Belgium"}>Belgium</option>
                            <option value={"Belize"}>Belize</option>
                            <option value={"Benin"}>Benin</option>
                            <option value={"Bermuda"}>Bermuda</option>
                            <option value={"Bhutan"}>Bhutan</option>
                            <option value={"Bolivia"}>Bolivia</option>
                            <option value={"Bosnia and Herzegovina"}>
                              Bosnia and Herzegovina
                            </option>
                            <option value={"Botswana"}>Botswana</option>
                            <option value={"Bouvet Island"}>
                              Bouvet Island
                            </option>
                            <option value={"Brazil"}>Brazil</option>
                            <option value={"British Indian Ocean Territory"}>
                              British Indian Ocean Territory
                            </option>
                            <option value={"Brunei Darussalam"}>
                              Brunei Darussalam
                            </option>
                            <option value={"Bulgaria"}>Bulgaria</option>
                            <option value={"Burkina Faso"}>Burkina Faso</option>
                            <option value={"Burundi"}>Burundi</option>
                            <option value={"Cambodia"}>Cambodia</option>
                            <option value={"Cameroon"}>Cameroon</option>
                            <option value={"Canada"}>Canada</option>
                            <option value={"Cape Verde"}>Cape Verde</option>
                            <option value={"Cayman Islands"}>
                              Cayman Islands
                            </option>
                            <option value={"Central African Republic"}>
                              Central African Republic
                            </option>
                            <option value={"Chad"}>Chad</option>
                            <option value={"Chile"}>Chile</option>
                            <option value={"China"}>China</option>
                            <option value={"Christmas Island"}>
                              Christmas Island
                            </option>
                            <option value={"Cocos (Keeling) Islands"}>
                              Cocos (Keeling) Islands
                            </option>
                            <option value={"Colombia"}>Colombia</option>
                            <option value={"Comoros"}>Comoros</option>
                            <option value={"Congo"}>Congo</option>
                            <option
                              value={"Congo, The Democratic Republic of the"}
                            >
                              Congo, The Democratic Republic of the
                            </option>
                            <option value={"Cook Islands"}>Cook Islands</option>
                            <option value={"Costa Rica"}>Costa Rica</option>
                            <option value={"Cote D'Ivoire"}>
                              Cote D"Ivoire
                            </option>
                            <option value={"Croatia"}>Croatia</option>
                            <option value={"Cuba"}>Cuba</option>
                            <option value={"Cyprus"}>Cyprus</option>
                            <option value={"Czech Republic"}>
                              Czech Republic
                            </option>
                            <option value={"Denmark"}>Denmark</option>
                            <option value={"Djibouti"}>Djibouti</option>
                            <option value={"Dominica"}>Dominica</option>
                            <option value={"Dominican Republic"}>
                              Dominican Republic
                            </option>
                            <option value={"Ecuador"}>Ecuador</option>
                            <option value={"Egypt"}>Egypt</option>
                            <option value={"El Salvador"}>El Salvador</option>
                            <option value={"Equatorial Guinea"}>
                              Equatorial Guinea
                            </option>
                            <option value={"Eritrea"}>Eritrea</option>
                            <option value={"Estonia"}>Estonia</option>
                            <option value={"Ethiopia"}>Ethiopia</option>
                            <option value={"Falkland Islands (Malvinas)"}>
                              Falkland Islands (Malvinas)
                            </option>
                            <option value={"Faroe Islands"}>
                              Faroe Islands
                            </option>
                            <option value={"Fiji"}>Fiji</option>
                            <option value={"Finland"}>Finland</option>
                            <option value={"France"}>France</option>
                            <option value={"French Guiana"}>
                              French Guiana
                            </option>
                            <option value={"French Polynesia"}>
                              French Polynesia
                            </option>
                            <option value={"French Southern Territories"}>
                              French Southern Territories
                            </option>
                            <option value={"Gabon"}>Gabon</option>
                            <option value={"Gambia"}>Gambia</option>
                            <option value={"Georgia"}>Georgia</option>
                            <option value={"Germany"}>Germany</option>
                            <option value={"Ghana"}>Ghana</option>
                            <option value={"Gibraltar"}>Gibraltar</option>
                            <option value={"Greece"}>Greece</option>
                            <option value={"Greenland"}>Greenland</option>
                            <option value={"Grenada"}>Grenada</option>
                            <option value={"Guadeloupe"}>Guadeloupe</option>
                            <option value={"Guam"}>Guam</option>
                            <option value={"Guatemala"}>Guatemala</option>
                            <option value={"Guernsey"}>Guernsey</option>
                            <option value={"Guinea"}>Guinea</option>
                            <option value={"Guinea-Bissau"}>
                              Guinea-Bissau
                            </option>
                            <option value={"Guyana"}>Guyana</option>
                            <option value={"Haiti"}>Haiti</option>
                            <option value={"Heard Island and Mcdonald Islands"}>
                              Heard Island and Mcdonald Islands
                            </option>
                            <option value={"Holy See (Vatican City State)"}>
                              Holy See (Vatican City State)
                            </option>
                            <option value={"Honduras"}>Honduras</option>
                            <option value={"Hong Kong"}>Hong Kong</option>
                            <option value={"Hungary"}>Hungary</option>
                            <option value={"Iceland"}>Iceland</option>
                            <option value={"India"}>India</option>
                            <option value={"Indonesia"}>Indonesia</option>
                            <option value={"Iran, Islamic Republic Of"}>
                              Iran, Islamic Republic Of
                            </option>
                            <option value={"Iraq"}>Iraq</option>
                            <option value={"Ireland"}>Ireland</option>
                            <option value={"Isle of Man"}>Isle of Man</option>
                            <option value={"Israel"}>Israel</option>
                            <option value={"Italy"}>Italy</option>
                            <option value={"Jamaica"}>Jamaica</option>
                            <option value={"Japan"}>Japan</option>
                            <option value={"Jersey"}>Jersey</option>
                            <option value={"Jordan"}>Jordan</option>
                            <option value={"Kazakhstan"}>Kazakhstan</option>
                            <option value={"Kenya"}>Kenya</option>
                            <option value={"Kiribati"}>Kiribati</option>
                            <option
                              value={"Korea, Democratic People'S Republic of"}
                            >
                              Korea, Democratic People"S Republic of
                            </option>
                            <option value={"Korea, Republic of"}>
                              Korea, Republic of
                            </option>
                            <option value={"Kuwait"}>Kuwait</option>
                            <option value={"Kyrgyzstan"}>Kyrgyzstan</option>
                            <option value={"Lao People'S Democratic Republic"}>
                              Lao People"S Democratic Republic
                            </option>
                            <option value={"Latvia"}>Latvia</option>
                            <option value={"Lebanon"}>Lebanon</option>
                            <option value={"Lesotho"}>Lesotho</option>
                            <option value={"Liberia"}>Liberia</option>
                            <option value={"Libyan Arab Jamahiriya"}>
                              Libyan Arab Jamahiriya
                            </option>
                            <option value={"Liechtenstein"}>
                              Liechtenstein
                            </option>
                            <option value={"Lithuania"}>Lithuania</option>
                            <option value={"Luxembourg"}>Luxembourg</option>
                            <option value={"Macao"}>Macao</option>
                            <option
                              value={
                                "Macedonia, The Former Yugoslav Republic of"
                              }
                            >
                              Macedonia, The Former Yugoslav Republic of
                            </option>
                            <option value={"Madagascar"}>Madagascar</option>
                            <option value={"Malawi"}>Malawi</option>
                            <option value={"Malaysia"}>Malaysia</option>
                            <option value={"Maldives"}>Maldives</option>
                            <option value={"Mali"}>Mali</option>
                            <option value={"Malta"}>Malta</option>
                            <option value={"Marshall Islands"}>
                              Marshall Islands
                            </option>
                            <option value={"Martinique"}>Martinique</option>
                            <option value={"Mauritania"}>Mauritania</option>
                            <option value={"Mauritius"}>Mauritius</option>
                            <option value={"Mayotte"}>Mayotte</option>
                            <option value={"Mexico"}>Mexico</option>
                            <option value={"Micronesia, Federated States of"}>
                              Micronesia, Federated States of
                            </option>
                            <option value={"Moldova, Republic of"}>
                              Moldova, Republic of
                            </option>
                            <option value={"Monaco"}>Monaco</option>
                            <option value={"Mongolia"}>Mongolia</option>
                            <option value={"Montserrat"}>Montserrat</option>
                            <option value={"Morocco"}>Morocco</option>
                            <option value={"Mozambique"}>Mozambique</option>
                            <option value={"Myanmar"}>Myanmar</option>
                            <option value={"Namibia"}>Namibia</option>
                            <option value={"Nauru"}>Nauru</option>
                            <option value={"Nepal"}>Nepal</option>
                            <option value={"Netherlands"}>Netherlands</option>
                            <option value={"Netherlands Antilles"}>
                              Netherlands Antilles
                            </option>
                            <option value={"New Caledonia"}>
                              New Caledonia
                            </option>
                            <option value={"New Zealand"}>New Zealand</option>
                            <option value={"Nicaragua"}>Nicaragua</option>
                            <option value={"Niger"}>Niger</option>
                            <option value={"Nigeria"}>Nigeria</option>
                            <option value={"Niue"}>Niue</option>
                            <option value={"Norfolk Island"}>
                              Norfolk Island
                            </option>
                            <option value={"Northern Mariana Islands"}>
                              Northern Mariana Islands
                            </option>
                            <option value={"Norway"}>Norway</option>
                            <option value={"Oman"}>Oman</option>
                            <option value={"Pakistan"}>Pakistan</option>
                            <option value={"Palau"}>Palau</option>
                            <option value={"Palestinian Territory, Occupied"}>
                              Palestinian Territory, Occupied
                            </option>
                            <option value={"Panama"}>Panama</option>
                            <option value={"Papua New Guinea"}>
                              Papua New Guinea
                            </option>
                            <option value={"Paraguay"}>Paraguay</option>
                            <option value={"Peru"}>Peru</option>
                            <option value={"Philippines"}>Philippines</option>
                            <option value={"Pitcairn"}>Pitcairn</option>
                            <option value={"Poland"}>Poland</option>
                            <option value={"Portugal"}>Portugal</option>
                            <option value={"Puerto Rico"}>Puerto Rico</option>
                            <option value={"Qatar"}>Qatar</option>
                            <option value={"Reunion"}>Reunion</option>
                            <option value={"Romania"}>Romania</option>
                            <option value={"Russian Federation"}>
                              Russian Federation
                            </option>
                            <option value={"RWANDA"}>RWANDA</option>
                            <option value={"Saint Helena"}>Saint Helena</option>
                            <option value={"Saint Kitts and Nevis"}>
                              Saint Kitts and Nevis
                            </option>
                            <option value={"Saint Lucia"}>Saint Lucia</option>
                            <option value={"Saint Pierre and Miquelon"}>
                              Saint Pierre and Miquelon
                            </option>
                            <option value={"Saint Vincent and the Grenadines"}>
                              Saint Vincent and the Grenadines
                            </option>
                            <option value={"Samoa"}>Samoa</option>
                            <option value={"San Marino"}>San Marino</option>
                            <option value={"Sao Tome and Principe"}>
                              Sao Tome and Principe
                            </option>
                            <option value={"Saudi Arabia"}>Saudi Arabia</option>
                            <option value={"Senegal"}>Senegal</option>
                            <option value={"Serbia and Montenegro"}>
                              Serbia and Montenegro
                            </option>
                            <option value={"Seychelles"}>Seychelles</option>
                            <option value={"Sierra Leone"}>Sierra Leone</option>
                            <option value={"Singapore"}>Singapore</option>
                            <option value={"Slovakia"}>Slovakia</option>
                            <option value={"Slovenia"}>Slovenia</option>
                            <option value={"Solomon Islands"}>
                              Solomon Islands
                            </option>
                            <option value={"Somalia"}>Somalia</option>
                            <option value={"South Africa"}>South Africa</option>
                            <option
                              value={
                                "South Georgia and the South Sandwich Islands"
                              }
                            >
                              South Georgia and the South Sandwich Islands
                            </option>
                            <option value={"Spain"}>Spain</option>
                            <option value={"Sri Lanka"}>Sri Lanka</option>
                            <option value={"Sudan"}>Sudan</option>
                            <option value={"Suriname"}>Suriname</option>
                            <option value={"Svalbard and Jan Mayen"}>
                              Svalbard and Jan Mayen
                            </option>
                            <option value={"Swaziland"}>Swaziland</option>
                            <option value={"Sweden"}>Sweden</option>
                            <option value={"Switzerland"}>Switzerland</option>
                            <option value={"Syrian Arab Republic"}>
                              Syrian Arab Republic
                            </option>
                            <option value={"Taiwan"}>Taiwan</option>
                            <option value={"Tajikistan"}>Tajikistan</option>
                            <option value={"Tanzania, United Republic of"}>
                              Tanzania, United Republic of
                            </option>
                            <option value={"Thailand"}>Thailand</option>
                            <option value={"Timor-Leste"}>Timor-Leste</option>
                            <option value={"Togo"}>Togo</option>
                            <option value={"Tokelau"}>Tokelau</option>
                            <option value={"Tonga"}>Tonga</option>
                            <option value={"Trinidad and Tobago"}>
                              Trinidad and Tobago
                            </option>
                            <option value={"Tunisia"}>Tunisia</option>
                            <option value={"Turkey"}>Turkey</option>
                            <option value={"Turkmenistan"}>Turkmenistan</option>
                            <option value={"Turks and Caicos Islands"}>
                              Turks and Caicos Islands
                            </option>
                            <option value={"Tuvalu"}>Tuvalu</option>
                            <option value={"Uganda"}>Uganda</option>
                            <option value={"Ukraine"}>Ukraine</option>
                            <option value={"United Arab Emirates"}>
                              United Arab Emirates
                            </option>
                            <option value={"United Kingdom"}>
                              United Kingdom
                            </option>
                            <option value={"United States"}>
                              United States
                            </option>
                            <option
                              value={"United States Minor Outlying Islands"}
                            >
                              United States Minor Outlying Islands
                            </option>
                            <option value={"Uruguay"}>Uruguay</option>
                            <option value={"Uzbekistan"}>Uzbekistan</option>
                            <option value={"Vanuatu"}>Vanuatu</option>
                            <option value={"Venezuela"}>Venezuela</option>
                            <option value={"Viet Nam"}>Viet Nam</option>
                            <option value={"Virgin Islands, British"}>
                              Virgin Islands, British
                            </option>
                            <option value={"Virgin Islands, U.S."}>
                              Virgin Islands, U.S.
                            </option>
                            <option value={"Wallis and Futuna"}>
                              Wallis and Futuna
                            </option>
                            <option value={"Western Sahara"}>
                              Western Sahara
                            </option>
                            <option value={"Yemen"}>Yemen</option>
                            <option value={"Zambia"}>Zambia</option>
                            <option value={"Zimbabwe"}>Zimbabwe</option>
                          </select>
                          <input
                            type="text"
                            placeholder="Address"
                            className="input input-bordered w-full block mb-5"
                            onChange={(e) => {
                              const value = e.target.value;
                              setFormData({ ...formData, address: value });
                            }}
                          />
                        </div>
                      </>
                    )}
                    <input
                      type="text"
                      placeholder="Post Code"
                      className="input input-bordered w-full block mb-5"
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData({ ...formData, postCode: value });
                      }}
                    />
                  </div>
                  <div className="mb-7">
                    <h3 className="text-lg mb-4">Security</h3>
                    <input
                      type="text"
                      placeholder="Password"
                      className="input input-bordered w-full block mb-5"
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData({ ...formData, password: value });
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Confirm Password"
                      className="input input-bordered w-full block mb-5"
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData({ ...formData, confirmPassword: value });
                      }}
                    />
                  </div>
                  <div className="mb-7">
                    <div className="form-control">
                      <label className="label cursor-pointer">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="checkbox checkbox-primary"
                          onChange={(e) => {
                            const value = e.target.checked;
                            setFormData({ ...formData, agreeTOS: value });
                          }}
                        />
                        <span className="label-text">
                          I agree to the Terms of Service and Privacy Policy.
                        </span>
                      </label>
                    </div>
                    <div className="mt-5 mb-4 flex items-center gap-10 justify-between">
                      <button
                        className="btn btn-outline border-blue-500 text-blue-500"
                        onClick={() => {
                          setStep(2);
                        }}
                      >
                        BACK
                      </button>
                      <button
                        className="btn bg-blue-500 text-white"
                        onClick={() => checkStep3Inputs()}
                      >
                        {isLoading ? "LOADING..." : "CONTINUE"}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </PrimaryLayout>
    </>
  );
}

export default Register;
