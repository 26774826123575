import React from "react";
import PrimaryLayout from "../components/layouts/primarylayout";
import { Link } from "react-router-dom";

function Firms() {
  return (
    <>
      <PrimaryLayout>
        {/* Banner */}
        <section
          className="h-80 w-full bg-cover flex flex-col justify-end"
          style={{ backgroundImage: `url(/images/firms-page/banner.jpg)` }}
        ></section>
        {/* Banner */}

        {/* Section 01 */}
        <section className="container mx-auto py-14">
          <h2 className="text-3xl font-bold text-blue-500">Fees and Levies</h2>
          <span className="block h-1 w-14 bg-blue-500 mt-3 mb-5"></span>

          <div className="flex items-center gap-16 text-sm">
            <p>First published: 08/05/2015</p>
            <p>Last updated: 13/07/2023</p>
            <Link to={"/"} className="text-blue-500">
              See all updates
            </Link>
          </div>

          <hr className="mt-8 mb-4" />
          <p>
            Find out about the fees we charge and what they cover, and the
            levies we collect for organisations that are part of the regulatory
            system.
          </p>
          <hr className="mt-4 mb-8" />

          <p>
            We are independent of Government. To finance our work we charge fees
            to:
          </p>
          <ul className="pl-7 list-disc mb-6">
            <li>the firms we authorise</li>
            <li>
              some other bodies such as recognised investment exchanges or
              registered firms
            </li>
          </ul>

          <p>There are 3 types of fee:</p>
          <ul className="pl-7 list-disc mb-6">
            <li>application – when you ask us to authorise your firm</li>
            <li>
              change to permissions – when you want to change a permission for
              an authorised activity
            </li>
            <li>annual (periodic) – which is payable each year</li>
          </ul>

          <p>We also collect fees and levies to pay for the costs of the:</p>
          <ul className="pl-7 list-disc mb-6">
            <li>
              <Link to={"/"} className="text-blue-600 underline">
                Payment Systems Regulator
              </Link>
            </li>
            <li>
              <Link to={"/"} className="text-blue-600 underline">
                Prudential Regulation Authority
              </Link>
            </li>
            <li>
              <Link to={"/"} className="text-blue-600 underline">
                Financial Ombudsman Service
              </Link>
            </li>
            <li>
              <Link to={"/"} className="text-blue-600 underline">
                Financial Services Compensation Scheme
              </Link>
            </li>
            <li>
              <Link to={"/"} className="text-blue-600 underline">
                Money and Pensions Service{" "}
                <span className="text-black no-underline">
                  ((Financial Guidance levies))
                </span>
              </Link>
            </li>
            <li>
              <Link to={"/"} className="text-blue-600 underline">
                Financial Reporting Council
              </Link>
            </li>
            <li>
              <Link to={"/"} className="text-blue-600 underline">
                Illegal Money Lending Team
              </Link>
            </li>
            <li>
              <Link to={"/"} className="text-blue-600 underline">
                Economic Crime Levy (ECL)
              </Link>
            </li>
          </ul>
        </section>
        {/* Section 01 */}
      </PrimaryLayout>
    </>
  );
}

export default Firms;
