import React from "react";
import { useNavigate } from "react-router-dom";

function AdminLogin() {
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    if (!email || !password) return alert("Email and Password are required");

    const findUser = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/users/login`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    );

    if (findUser.ok) {
      const userData = await findUser.json(); // Parse the JSON from the response

      console.log(userData); // Do something with the user data

      if (userData.length < 1) {
        alert("User Not Found");
      } else if (userData[0].role !== "admin") {
        alert("Please Login As a admin");
      } else {
        localStorage.setItem(
          "dit-user",
          JSON.stringify({ id: 1, email, password, role: "admin" })
        );
        navigate("/admin");
      }
    } else {
      alert("Something Went Wrong");
      console.error("Login failed", findUser.statusText);
    }
  };

  return (
    <section className="w-full h-screen flex items-center justify-center">
      <div className="mx-auto w-full max-w-xl">
        <div className="shadow py-7 px-5 bg-white border border-gray-400 rounded">
          <h2 className="text-center text-xl font-bold mb-5">Sign In</h2>
          <form action="" onSubmit={(e) => handleLogin(e)}>
            <div className="mb-3">
              <input
                type="text"
                className="w-full input border border-slate-400"
                name="email"
                placeholder="Email"
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                className="w-full input border border-slate-400"
                name="password"
                placeholder="Password"
              />
            </div>
            <div className="mb-3">
              <button className="btn w-full bg-blue-600 text-white text-center">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default AdminLogin;
