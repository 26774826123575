import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardFooter from "../common/dashboardfooter";
import { Home, ListCheck, Mail, Menu, UserSquareIcon } from "lucide-react";
import { useEffect } from "react";
import { useTime } from "day-time-greet";

function DashboardLayout({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  const { time } = useTime();

  const currentUser = JSON.parse(localStorage.getItem("dit-user"));

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    } else if (currentUser?.role === "admin") {
      navigate("/login");
    }
  }, [navigate, currentUser]);

  const currentPage = location.pathname;

  const handleLogout = () => {
    localStorage.removeItem("dit-user");
    navigate("/login");
  };

  const MenuItems = () => (
    <div className="flex flex-col mt-5">
      <Link
        to={"/dashboard"}
        className={` flex items-center gap-4 py-3 px-5 ${
          currentPage === "/dashboard"
            ? "bg-gray-100 text-blue-500 border-l-4 border-l-blue-500"
            : "border-b border-b-gray-400"
        }`}
      >
        <span>
          <Home />
        </span>
        <span>Dashboard</span>
      </Link>
      <Link
        to={"/transaction"}
        className={`flex items-center gap-4 py-3 px-5 ${
          currentPage === "/transaction"
            ? "bg-gray-100 text-blue-500 border-l-4 border-l-blue-500"
            : "border-b border-b-gray-400"
        }`}
      >
        <span>
          <ListCheck />
        </span>
        <span>Transactions</span>
      </Link>
      <Link
        to={"/inbox"}
        className={`flex items-center gap-4 py-3 px-5 ${
          currentPage === "/inbox"
            ? "bg-gray-100 text-blue-500 border-l-4 border-l-blue-500"
            : "border-b border-b-gray-400"
        }`}
      >
        <span>
          <Mail />
        </span>
        <span>Inbox</span>
      </Link>
      <Link
        to={"/profile"}
        className={`flex items-center gap-4 py-3 px-5 ${
          currentPage === "/profile"
            ? "bg-gray-100 text-blue-500 border-l-4 border-l-blue-500"
            : "border-b border-b-gray-400"
        }`}
      >
        <span>
          <UserSquareIcon />
        </span>
        <span>Profile</span>
      </Link>
    </div>
  );

  return (
    <main className="w-full h-screen flex">
      <div className="w-full max-w-[200px] md:flex flex-col justify-between border-r-2 border-r-blue-600 hidden">
        <div>
          <img
            src="/images/logo-for-user-dashboard.png"
            alt="Logo For User Dashboard"
          />
          <MenuItems />
        </div>
        <div>
          <button className="btn w-full" onClick={() => handleLogout()}>
            <span>Logout</span>
          </button>
        </div>
      </div>
      <div className="flex-1 w-full py-8 px-5 overflow-scroll">
        <div className="w-full">
          <div className="flex items-center gap-4">
            <div className="drawer w-fit md:hidden grid">
              <input id="my-drawer" type="checkbox" className="drawer-toggle" />
              <div className="drawer-content w-fit">
                {/* Page content here */}
                <label
                  htmlFor="my-drawer"
                  className="btn btn-primary drawer-button"
                >
                  <Menu />
                </label>
              </div>
              <div className="drawer-side">
                <label
                  htmlFor="my-drawer"
                  aria-label="close sidebar"
                  className="drawer-overlay"
                ></label>
                <div className="bg-white h-full">
                  <MenuItems />
                </div>
              </div>
            </div>
            <h2 className="text-3xl font-semibold text-blue-500">
              {time}, {currentUser[0]?.first_name}
            </h2>
          </div>

          <hr className="mt-3 mb-4" />
        </div>
        {children}
        <DashboardFooter />
      </div>
    </main>
  );
}

export default DashboardLayout;
