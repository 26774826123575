import React from "react";
import { Link, useNavigate } from "react-router-dom";

function AdminHeader() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("dit-user");
    navigate("/adminlogin");
  };

  return (
    <header className="py-4 px-7 bg-black flex items-center justify-between gap-7">
      <Link to={"/admin"} className="text-white text-xl">
        HEBAYA
      </Link>
      <ul className="text-white flex items-center gap-6">
        <li>
          <Link to={"/admin"}>Home</Link>
        </li>
        <li>
          <button onClick={() => handleLogout()}>Logout</button>
        </li>
      </ul>
    </header>
  );
}

export default AdminHeader;
