import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header>
      {/* Top Bar */}
      <section className="bg-slate-800">
        <div className="container mx-auto">
          <div className="navbar bg-slate-700 text-white py-0 min-h-fit">
            <div className="navbar-start">
              <ul className="menu menu-horizontal px-1">
                <li>
                  <Link to="/" className="text-xs">
                    About DIT
                  </Link>
                </li>
                <li>
                  <Link to="/personal" className="text-xs">
                    Personal
                  </Link>
                </li>
              </ul>
            </div>
            <div className="navbar-center hidden lg:flex">
              <div className="form-control">
                <input
                  type="text"
                  placeholder="Search"
                  className="input input-bordered w-24 md:w-auto input-sm"
                />
              </div>
            </div>
            <div className="navbar-end">
              <Link className="btn btn-sm" to="/login">
                Log In
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Top Bar */}

      {/* Menu */}
      <section className="border-b-2 border-b-[#1e90ff]">
        <div className="navbar bg-base-100">
          <div className="navbar-start">
            <div className="dropdown">
              <div
                tabIndex={0}
                role="button"
                className="btn btn-ghost lg:hidden"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-80 p-2 shadow"
              >
                <li className="border-b border-b-slate-400 py-3">
                  <Link
                    to="/"
                    className="flex justify-between items-center gap-1"
                  >
                    <span className="text-sm text-[#1e90ff]">About DIT</span>
                    <span className="text-xs text-[#aaaaaa]">Who We Are</span>
                  </Link>
                </li>
                <li className="border-b border-b-slate-400 py-3">
                  <Link
                    to="/firms"
                    className="flex justify-between items-center gap-1"
                  >
                    <span className="text-sm text-[#1e90ff]">Firms</span>
                    <span className="text-xs text-[#aaaaaa]">
                      Reports, Accounts, and More
                    </span>
                  </Link>
                </li>
                <li className="border-b border-b-slate-400 py-3">
                  <Link
                    to="/markets"
                    className="flex justify-between items-center gap-1"
                  >
                    <span className="text-sm text-[#1e90ff]">Markets</span>
                    <span className="text-xs text-[#aaaaaa]">
                      Environmental Social & Governance
                    </span>
                  </Link>
                </li>
                <li className="border-b border-b-slate-400 py-3">
                  <Link
                    to="/consumers"
                    className="flex justify-between items-center gap-1"
                  >
                    <span className="text-sm text-[#1e90ff]">Consumers</span>
                    <span className="text-xs text-[#aaaaaa]">
                      Human Resources
                    </span>
                  </Link>
                </li>
                <li className="border-b border-b-slate-400 py-3">
                  <Link
                    to="/security"
                    className="flex justify-between items-center gap-1"
                  >
                    <span className="text-sm text-[#1e90ff]">Security</span>
                    <span className="text-xs text-[#aaaaaa]">
                      Online Security
                    </span>
                  </Link>
                </li>
                <li className="border-b border-b-slate-400 py-3">
                  <Link
                    to="/newsroom"
                    className="flex justify-between items-center gap-1"
                  >
                    <span className="text-sm text-[#1e90ff]">Newsroom</span>
                    <span className="text-xs text-[#aaaaaa]">2010-2023</span>
                  </Link>
                </li>
              </ul>
            </div>
            <Link className="" to="/">
              <img
                src="/images/logo.png"
                alt="Logo"
                className="w-full max-w-[120px]"
              />
            </Link>
          </div>
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal px-1">
              <li>
                <Link to="/" className="flex flex-col gap-1 items-start">
                  <span className="text-sm">About DIT</span>
                  <span className="text-xs text-[#aaaaaa]">Who We Are</span>
                </Link>
              </li>
              <li>
                <Link to="/firms" className="flex flex-col gap-1 items-start">
                  <span className="text-sm">Firms</span>
                  <span className="text-xs text-[#aaaaaa]">
                    Reports, Accounts, and More
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/markets" className="flex flex-col gap-1 items-start">
                  <span className="text-sm">Markets</span>
                  <span className="text-xs text-[#aaaaaa]">
                    Environmental Social & Governance
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/consumers"
                  className="flex flex-col gap-1 items-start"
                >
                  <span className="text-sm">Consumers</span>
                  <span className="text-xs text-[#aaaaaa]">
                    Human Resources
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/security"
                  className="flex flex-col gap-1 items-start"
                >
                  <span className="text-sm">Security</span>
                  <span className="text-xs text-[#aaaaaa]">
                    Online Security
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/newsroom"
                  className="flex flex-col gap-1 items-start"
                >
                  <span className="text-sm">Newsroom</span>
                  <span className="text-xs text-[#aaaaaa]">2010-2023</span>
                </Link>
              </li>
            </ul>
          </div>

          {/* <div className="navbar-end">
            
          </div> */}
        </div>
      </section>
      {/* Menu */}
    </header>
  );
}

export default Header;
