import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/layouts/adminlayout";
import { Eye, MinusCircle, UserCircle } from "lucide-react";
import { Link } from "react-router-dom";

function Admin() {
  const [allUsers, setAllUsers] = useState([]);

  const getAllUsers = async () => {
    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/users`
    );

    if (response.ok) {
      const users = await response.json();
      console.log(users);
      setAllUsers(users);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <>
      <AdminLayout>
        <div className="mx-auto w-full max-w-5xl shadow mt-5 border border-blue-400">
          <div className="overflow-x-auto">
            <table className="table">
              {/* head */}
              <thead className="bg-gray-200">
                <tr>
                  <th>
                    <label>
                      <input type="checkbox" className="checkbox" />
                    </label>
                  </th>
                  <th>Country</th>
                  <th>Email</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Role</th>
                  <th>Actions</th>
                  <th>Status</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {allUsers?.map((user) => (
                  <tr key={user?.id}>
                    <th>
                      <label>
                        <input type="checkbox" className="checkbox" />
                      </label>
                    </th>
                    <td>{user?.country}</td>
                    <td>
                      <span className="badge badge-ghost badge-sm">
                        {user?.email}
                      </span>
                    </td>
                    <td>{user?.first_name}</td>
                    <td>{user?.last_name}</td>
                    <td>
                      <UserCircle />
                    </td>
                    <td>
                      <Link to={`/user/${user?.id}`} className="text-green-700">
                        <Eye />
                      </Link>
                    </td>
                    <td>WAITBA</td>
                    <th>
                      <button className="btn btn-xs text-red-500">
                        <MinusCircle />
                      </button>
                    </th>
                  </tr>
                ))}
              </tbody>
              {/* foot */}
              <tfoot className="bg-gray-100">
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </AdminLayout>
    </>
  );
}

export default Admin;
