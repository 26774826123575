import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layouts/dashboardlayout";
import { Building, Camera, CheckCircle, FileUp } from "lucide-react";
import TransactionTotal from "../../components/dashboard/transactionTotal";

function Dashbaord() {
  const currentUser = JSON.parse(localStorage.getItem("dit-user"));

  const id = currentUser[0]?.id;

  const [user, setUser] = useState({});
  const [accountStatus, setAccountStatus] = useState({});
  const [bank, setBank] = useState({
    bank_name: "",
    swift_code: "",
    iban: "",
    account_holder_name: "",
  });
  const [ditAccounts, setDitAccounts] = useState([]);

  const getUser = async () => {
    const response = await fetch(
      `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/users/${id}`
    );

    if (response.ok) {
      const users = await response.json();
      setUser(users);

      const res = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/users/status/${id}`
      );

      const ress = await res.json();
      const currentAcStatus = ress.find(
        (st) => st.status_type === users.status
      );
      setAccountStatus(currentAcStatus);

      const bank = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/bank/user/${id}`
      );

      if (bank.ok) {
        const bankRes = await bank.json();
        setBank(bankRes);
      }

      const ditAc = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/dit/user/${id}`
      );

      if (ditAc.ok) {
        const ditAcRes = await ditAc.json();
        setDitAccounts(ditAcRes);
      }
    }
  };

  useEffect(() => {
    getUser();
  }, [id]);

  const handleAddStatus = async (e, type) => {
    e.preventDefault();
    const selectedFile = e.target.file.files[0];

    console.log(selectedFile);

    if (!selectedFile) {
      return alert("Please Choose A File");
    }

    const formData = new FormData();
    const fileInput = e.target.file;
    formData.append("file", fileInput.files[0]);

    try {
      const responsee = await fetch(
        "https://lawrecoveryportal.com/upload.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (responsee.status === "error") {
        return alert(responsee.message);
      }

      const file = await responsee.json();
      const fileUrl = file.fileUrl;

      const response = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/users/status`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            media: fileUrl,
            is_done: 1,
            uid: id,
            status_type: type,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Status update failed: ${errorText}`);
      }

      console.log(await response.json());
      alert("Done");
    } catch (error) {
      console.error("Error:", error);
      alert(error.message);
    }
  };

  const handleAddBank = async (e) => {
    e.preventDefault();

    const bank_name = e.target.bank_name.value;
    const swift_code = e.target.swift_code.value;
    const iban = e.target.iban.value;
    const account_holder_name = e.target.account_holder_name.value;

    if (
      bank_name === "" ||
      swift_code === "" ||
      iban === "" ||
      account_holder_name === ""
    ) {
      return alert("Please Fill Up All Field");
    }

    try {
      const response = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/bank`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            uid: id,
            bank_name: bank_name,
            swift_code: swift_code,
            iban: iban,
            account_holder_name: account_holder_name,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Bank Details Add failed: ${errorText}`);
      }

      const res = await fetch(
        `https://oran-asaraf-dit-project-back-end-u2q3.vercel.app/api/users/status`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            media: "",
            is_done: 1,
            uid: id,
            status_type: "ask-bank-details",
          }),
        }
      );

      if (res.ok) {
        console.log(await response.json());
        alert("Done");
      }
    } catch (error) {
      console.error("Error:", error);
      alert(error.message);
    }
  };

  return (
    <>
      <DashboardLayout>
        <div>
          {user?.status === "new" && (
            <>
              <div className="bg-blue-50 py-6 px-7 flex gap-4">
                <div>
                  <img
                    src="/images/awaiting-icon.png"
                    alt=""
                    className="w-full max-w-[20px]"
                  />
                </div>
                <div>
                  <p className="font-semibold mb-4">
                    Registration Submitted – Awaiting Verification
                  </p>
                  <p className="text-sm">
                    Your submission has been received and is currently under
                    verification. The verification process is vital to ensure
                    compliance with the requisite security measures and
                    regulations.
                  </p>
                </div>
              </div>
            </>
          )}
          {user?.status === "pending" && (
            <>
              <div className="bg-blue-50 py-6 px-7 flex gap-4">
                <div>
                  <img
                    src="/images/awaiting-icon.png"
                    alt=""
                    className="w-full max-w-[20px]"
                  />
                </div>
                <div>
                  <p className="font-semibold mb-4">
                    Registration Submitted – Awaiting Verification
                  </p>
                  <p className="text-sm">
                    Your submission has been received and is currently under
                    verification. The verification process is vital to ensure
                    compliance with the requisite security measures and
                    regulations.
                  </p>
                </div>
              </div>
            </>
          )}
          {user?.status === "ask-id-passport" && (
            <>
              <div
                className={`py-6 px-7 flex gap-4 ${
                  accountStatus?.is_done ? "bg-green-50" : "bg-red-50"
                }`}
              >
                <div>
                  {accountStatus?.is_done ? (
                    <CheckCircle className="w-full max-w-[20px] text-green-700" />
                  ) : (
                    <Camera className="w-full max-w-[20px] text-red-700" />
                  )}
                </div>
                <div>
                  {accountStatus?.is_done ? (
                    <>
                      <p className="text-white bg-teal-600 p-4 text-center">
                        Upload Successful
                        <br />
                        Thank you for submitting your document. It is currently
                        under review.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="font-semibold mb-4">
                        Document Submission Required
                      </p>
                      <p className="text-sm mb-3">
                        To continue with your registration, please submit a
                        photo ID or passport.
                      </p>

                      <form
                        action=""
                        className="flex items-center justify-center w-full mt-4 gap-5"
                        onSubmit={(e) => handleAddStatus(e, "ask-id-passport")}
                      >
                        <label
                          htmlFor="bank-statement"
                          className="border-2 border-sky-600 block p-2 rounded"
                        >
                          <input type="file" name="file" />
                        </label>
                        <button className="btn bg-teal-600 text-white px-8">
                          Submit
                        </button>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {user?.status === "ask-bank-details" && (
            <>
              <div className="bg-red-50 py-6 px-7 flex gap-4">
                <div>
                  <Building className="w-full max-w-[20px]" />
                </div>
                <div>
                  <p className="font-semibold mb-4">
                    Banking Details <br /> Enter Your Banking Information
                  </p>
                  <p className="text-sm">
                    Your banking details are required to initiate and process
                    transactions securely.
                  </p>
                  {accountStatus?.is_done ? (
                    <>
                      <p className="text-white bg-teal-600 p-4 text-center">
                        Done
                      </p>
                    </>
                  ) : (
                    <>
                      <form
                        action=""
                        className="flex flex-col w-full mt-4 gap-5"
                        onSubmit={(e) => handleAddBank(e)}
                      >
                        <div className="flex flex-col gap-2">
                          <label htmlFor="">Bank Name</label>
                          <input
                            type="text"
                            name="bank_name"
                            className="input"
                            list="banks"
                          />
                          <datalist id="banks">
                            <option value="Ahli United Bank (AUB)">
                              Ahli United Bank (AUB)
                            </option>
                            <option value="Bahrain Islamic Bank (BIB)">
                              Bahrain Islamic Bank (BIB)
                            </option>
                            <option value="Bank of Bahrain and Kuwait (BBK)">
                              Bank of Bahrain and Kuwait (BBK)
                            </option>
                            <option value="National Bank of Bahrain (NBB)">
                              National Bank of Bahrain (NBB)
                            </option>
                            <option value="National Bank of Kuwait (NBK)">
                              National Bank of Kuwait (NBK)
                            </option>
                            <option value="Kuwait Finance House (KFH)">
                              Kuwait Finance House (KFH)
                            </option>
                            <option value="Al Ahli Bank of Kuwait (ABK)">
                              Al Ahli Bank of Kuwait (ABK)
                            </option>
                            <option value="Burgan Bank (BB)">
                              Burgan Bank (BB)
                            </option>
                            <option value="Bank Muscat (BM)">
                              Bank Muscat (BM)
                            </option>
                            <option value="National Bank of Oman (NBO)">
                              National Bank of Oman (NBO)
                            </option>
                            <option value="Bank Dhofar (BD)">
                              Bank Dhofar (BD)
                            </option>
                            <option value="Oman Arab Bank (OAB)">
                              Oman Arab Bank (OAB)
                            </option>
                            <option value="Qatar National Bank (QNB)">
                              Qatar National Bank (QNB)
                            </option>
                            <option value="Commercial Bank of Qatar (CBQ)">
                              Commercial Bank of Qatar (CBQ)
                            </option>
                            <option value="Doha Bank (DB)">
                              Doha Bank (DB)
                            </option>
                            <option value="Qatar Islamic Bank (QIB)">
                              Qatar Islamic Bank (QIB)
                            </option>
                            <option value="National Commercial Bank (NCB)">
                              National Commercial Bank (NCB)
                            </option>
                            <option value="Al Rajhi Bank (ARB)">
                              Al Rajhi Bank (ARB)
                            </option>
                            <option value="Samba Financial Group (SFG)">
                              Samba Financial Group (SFG)
                            </option>
                            <option value="Riyad Bank (RB)">
                              Riyad Bank (RB)
                            </option>
                            <option value="Emirates NBD (ENBD)">
                              Emirates NBD (ENBD)
                            </option>
                            <option value="Abu Dhabi Commercial Bank (ADCB)">
                              Abu Dhabi Commercial Bank (ADCB)
                            </option>
                            <option value="First Abu Dhabi Bank (FAB)">
                              First Abu Dhabi Bank (FAB)
                            </option>
                            <option value="Dubai Islamic Bank (DIB)">
                              Dubai Islamic Bank (DIB)
                            </option>
                          </datalist>
                          <span className="text-xs">
                            Select or Type your bank name
                          </span>
                        </div>
                        <div className="flex flex-col gap-2">
                          <label htmlFor="">IBAN</label>
                          <input type="text" name="iban" className="input" />
                          <span className="text-xs">
                            Your International Bank Account Number
                          </span>
                        </div>
                        <div className="flex flex-col gap-2">
                          <label htmlFor="">SWIFT/BIC Code</label>
                          <input
                            type="text"
                            name="swift_code"
                            className="input"
                          />
                          <span className="text-xs">
                            The SWIFT code for your bank.
                          </span>
                        </div>
                        <div className="flex flex-col gap-2">
                          <label htmlFor="">Account Holder's Name</label>
                          <input
                            type="text"
                            name="account_holder_name"
                            className="input"
                          />
                          <span className="text-xs">
                            The Name registered with your bank account.
                          </span>
                        </div>
                        <button className="btn bg-teal-600 text-white px-8">
                          Submit
                        </button>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {user?.status === "ask-bank-statement" && (
            <>
              <div className="bg-sky-100 py-6 px-7 flex gap-4">
                <div>
                  <FileUp className="w-full max-w-[20px] text-sky-600" />
                </div>
                <div>
                  <p className="font-semibold mb-4">
                    Bank Statement Submission Required
                  </p>
                  <p className="text-sm">
                    To verify account ownership and balance, please submit your
                    most recent bank statement.
                  </p>
                  {accountStatus?.is_done ? (
                    <>
                      <p className="text-white bg-teal-600 p-4 text-center">
                        Done
                      </p>
                    </>
                  ) : (
                    <>
                      <form
                        action=""
                        className="flex items-center justify-center w-full mt-4 gap-5"
                        onSubmit={(e) =>
                          handleAddStatus(e, "ask-bank-statement")
                        }
                      >
                        <label
                          htmlFor="bank-statement"
                          className="border-2 border-sky-600 block p-2 rounded"
                        >
                          <input type="file" name="file" />
                        </label>
                        <button className="btn bg-teal-600 text-white px-8">
                          Submit
                        </button>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {user?.status === "show-dit-details" && (
            <>
              <div className="bg-teal-50 py-6 px-7 flex gap-4">
                <div>
                  <CheckCircle className="w-full max-w-[20px] text-teal-500" />
                </div>
                <div>
                  <p className="font-semibold mb-4">
                    Account Recovery Complete
                  </p>
                  <p className="text-sm">
                    The detailed scrutiny of your account registration has
                    concluded successfully.
                  </p>
                  {accountStatus?.is_done ? (
                    <>
                      <p className="text-white bg-teal-600 p-4 text-center">
                        Done
                      </p>
                    </>
                  ) : (
                    <>
                      {/* <form
                        action=""
                        className="flex items-center justify-center w-full mt-4 gap-5"
                        onSubmit={(e) => handleAddStatus(e, "show-dit-details")}
                      >
                        <label
                          htmlFor="bank-statement"
                          className="border-2 border-sky-600 block p-2 rounded"
                        >
                          <input type="file" name="file" />
                        </label>
                        <button className="btn bg-teal-600 text-white px-8">
                          Submit
                        </button>
                      </form> */}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {user?.status === "confirm-bank-details" && (
            <>
              <div className="bg-sky-100 py-6 px-7 flex gap-4">
                <div>
                  <img
                    src="/images/awaiting-icon.png"
                    alt=""
                    className="w-full max-w-[20px]"
                  />
                </div>
                <div>
                  <p className="font-semibold mb-4">
                    Banking Information Received
                  </p>
                  <p className="text-sm">
                    We have received your banking details and are currently
                    awaiting process confirmation and validation. Please be
                    patient as this may take some time.
                  </p>
                  <ul className="list-disc pl-6 flex flex-col gap-4">
                    <li className="flex flex-col gap-2">
                      <p>Bank Name</p>
                      <span>
                        {bank?.bank_name !== "" ? bank.bank_name : "N/A"}
                      </span>
                    </li>
                    <li className="flex flex-col gap-2">
                      <p>IBAN</p>
                      <span>{bank?.iban !== "" ? bank.iban : "N/A"}</span>
                    </li>
                    <li className="flex flex-col gap-2">
                      <p>SWIFT/BIC</p>
                      <span>
                        {bank?.swift_code !== "" ? bank.swift_code : "N/A"}
                      </span>
                    </li>
                    <li className="flex flex-col gap-2">
                      <p>Account Holder's Name</p>
                      <span>
                        {bank?.account_holder_name !== ""
                          ? bank.account_holder_name
                          : "N/A"}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
          {user?.status === "A1" && (
            <>
              <p>Other status: A1</p>
            </>
          )}

          <p className="mt-4">
            Notification regarding the verification outcome and any further
            requirements will be communicated promptly through your account's
            inbox. It is imperative for the efficiency of this process that your
            contact information remains current and accurate.
          </p>
          <hr className="mt-4" />

          <div className="container mx-auto">
            <div className="overflow-x-auto">
              <table className="table">
                {/* head */}
                <thead>
                  <tr>
                    <th>Account # </th>
                    <th>Date Registered </th>
                    <th>Balance (€) </th>
                    <th>Account Status</th>
                  </tr>
                </thead>
                <tbody>
                  {/* row 1 */}
                  {ditAccounts.length > 0 ? (
                    ditAccounts?.map((ac) => (
                      <>
                        <tr>
                          <th>{ac.account_number}</th>
                          <td>{ac.date}</td>
                          <td>
                            <span
                              className={
                                ac.status === "active"
                                  ? "text-green-600"
                                  : "text-red-600"
                              }
                            >
                              ${ac.amount}
                            </span>
                          </td>
                          <td>
                            <span
                              className={
                                ac.status === "active"
                                  ? "text-green-600"
                                  : "text-red-600"
                              }
                            >
                              {ac.status}
                            </span>
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <TransactionTotal />
        </div>
      </DashboardLayout>
    </>
  );
}

export default Dashbaord;
