import React from "react";
import PrimaryLayout from "../components/layouts/primarylayout";
import { Link } from "react-router-dom";

function Markets() {
  return (
    <>
      <PrimaryLayout>
        {/* Banner */}
        <section
          className="h-80 w-full bg-cover flex flex-col justify-end"
          style={{ backgroundImage: `url(/images/markets-page/banner.jpg)` }}
        ></section>
        {/* Banner */}

        {/* Section 01 */}
        <section className="container mx-auto py-14">
          <h2 className="text-3xl font-bold text-blue-500">
            Market Regulations
          </h2>
          <span className="block h-1 w-14 bg-blue-500 mt-3 mb-5"></span>

          <div className="flex items-center gap-16 text-sm">
            <p>First published: 08/05/2015</p>
            <p>Last updated: 13/07/2023</p>
            <Link to={"/"} className="text-blue-500">
              See all updates
            </Link>
          </div>

          <hr className="mt-8 mb-4" />
          <p>
            We regulate financial services markets, including exchanges and the
            issuers of securities, to ensure fair and transparent market
            practices. Our regulatory framework is designed to protect
            investors, maintain fair, efficient, and transparent markets, and
            reduce systemic risk.
          </p>

          <h2 className="text-blue-500 font-bold text-2xl">
            Our Responsibilities
          </h2>
          <ul className="pl-7 list-disc mb-6">
            <li>Overseeing the conduct of market participants</li>
            <li>Ensuring the integrity of financial products and services</li>
            <li>
              Monitoring market transactions to prevent fraud and market abuse
            </li>
            <li>Setting standards for corporate governance and disclosure</li>
          </ul>

          <h2 className="text-blue-500 font-bold text-2xl">Regulatory Tools</h2>
          <p>Our regulatory toolkit includes:</p>
          <ul className="pl-7 list-disc mb-6">
            <li>Rulemaking, to create robust regulatory standards</li>
            <li>
              Supervision, to oversee the operations of financial institutions
            </li>
            <li>Enforcement, to act against violations of our regulations</li>
            <li>
              Examination, to ensure compliance and assess the risk profile of
              entities
            </li>
          </ul>

          <h2 className="text-blue-500 font-bold text-2xl">
            Collaboration with Other Regulators
          </h2>
          <p>
            We work closely with domestic and international regulatory bodies to
            coordinate regulations across different jurisdictions and to tackle
            challenges posed by the global nature of financial markets.
          </p>

          <h2 className="text-blue-500 font-bold text-2xl">Stay Informed</h2>
          <p>
            To stay up-to-date with the latest regulatory developments, please
            subscribe to our newsletter or visit the regulatory updates section
            on our website.
          </p>
          <p>If you have any queries or require further information, please</p>
          <p>
            <Link to={"/"} className="text-xs underline text-slate-600">
              contact us
            </Link>
          </p>
        </section>
        {/* Section 01 */}
      </PrimaryLayout>
    </>
  );
}

export default Markets;
