import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PrimaryLayout from "../components/layouts/primarylayout";

function SingleNews() {
  const { id } = useParams();

  const news = [
    {
      id: 1,
      title:
        "Mohammed Zina sentenced to 22 months in prison for insider dealing and fraud",
      desc: "Mohammed Zina, formerly a respected figure in the finance sector, faced a significant downfall as he was sentenced to 22 months in prison for insider dealing and fraud. This case, notable for its complexity, concluded with a guilty verdict that sent ripples through the financial community. Zina's crimes involved the manipulation of confidential, market-sensitive information for his own financial gain. The investigation into his actions uncovered a sophisticated scheme of secret meetings, coded communications, and unauthorized financial transactions. His conviction is a clear message to the financial industry about the serious consequences of engaging in such unethical and illegal activities.",
      date: "16/02/2024",
      category: "Press Releases",
    },
    {
      id: 2,
      title: "DIT secures bankruptcy of Avacade directors",
      desc: "In a notable achievement for the Department of International Trade (DIT), the bankruptcy of the directors of Avacade, an investment firm, was secured following allegations of fraudulent activities. This case highlights the directors' engagement in unscrupulous practices, including misappropriation of investor funds and deceptive financial tactics. The exhaustive investigation revealed a pattern of unethical behavior resulting in significant losses for investors. This development not only underscores the DIT's dedication to maintaining financial regulation and investor protection but also serves as a stern warning to corporate entities about the importance of ethical management and the repercussions of financial misdeeds.",
      date: "16/02/2024",
      category: "News Stories",
    },
    {
      id: 3,
      title:
        "Convicted illegal money lender imprisoned for failing to pay confiscation order",
      desc: "In a move that highlights the seriousness of financial crimes, a convicted illegal money lender was imprisoned after failing to comply with a confiscation order. This individual, known for exploiting financially vulnerable people with excessive interest rates and aggressive collection methods, faced the consequences of their illegal lending practices. The case sheds light on the predatory side of finance, where individuals exploit those in dire financial straits. The lender's imprisonment marks a victory in the ongoing efforts to regulate financial activities and protect consumers from such exploitative practices.",
      date: "16/02/2024",
      category: "Press Releases",
    },
    {
      id: 4,
      title: "DIT announces new partnership with global fintech leaders",
      desc: "The Department of International Trade (DIT) announced a strategic partnership with global leaders in financial technology. This collaboration is aimed at fostering innovation in fintech, enhancing cross-border transactions, and introducing advanced financial solutions. This partnership is expected to revolutionize various aspects of finance, including payment processing, digital banking, and cybersecurity, by leveraging technological advancements. This initiative demonstrates DIT's commitment to promoting innovation and technological integration in the global financial sector, benefitting both businesses and consumers.",
      date: "10/03/2024",
      category: "Company News",
    },
    {
      id: 5,
      title:
        "Innovative solutions introduced for cross-border payment challenges",
      desc: "Addressing the complexities of international finance, a new range of solutions for cross-border payment challenges has been introduced. These innovations aim to simplify international transactions, reduce costs, and bolster security. Developed through collaboration between financial experts and technological innovators, these solutions tackle key issues such as currency conversion, compliance with diverse international regulations, and enhancing the speed of transactions. This development represents a significant step towards a more interconnected and efficient global financial system, offering substantial benefits to both businesses and individual consumers.",
      date: "25/03/2024",
      category: "Product Releases",
    },
    {
      id: 6,
      title:
        "DIT recognized for outstanding contributions to international trade",
      desc: "The Department of International Trade (DIT) was recently recognized with an award for its exceptional contributions to international trade. This award acknowledges DIT's efforts in fostering fair trade practices, formulating comprehensive trade agreements, and assisting businesses in the global marketplace. DIT's work has significantly influenced the dynamics of global trade, facilitating smoother trade relations and promoting economic growth. This accolade not only celebrates DIT's achievements but also highlights the importance of cooperative and ethical practices in international trade in today's globally connected economy.",
      date: "02/04/2024",
      category: "Awards",
    },
  ];

  const [currentNews, setCurrentNews] = useState({});

  useEffect(() => {
    const findNews = news.find((news) => news.id == id);
    setCurrentNews(findNews);
  }, [id]);

  console.log(currentNews);

  return (
    <PrimaryLayout>
      <section className="container mx-auto my-14">
        <div className="mx-auto w-full max-w-3xl">
          <h1 className="md:text-4xl text-2xl font-bold">
            {currentNews?.title}
          </h1>
          <div className="flex items-center gap-10 mt-3 mb-7">
            <span className="text-sm">{currentNews?.date}</span>
            <span className="text-sm font-bold">{currentNews?.category}</span>
          </div>
          <p>{currentNews?.desc}</p>
        </div>
      </section>
    </PrimaryLayout>
  );
}

export default SingleNews;
