import React from "react";
import PrimaryLayout from "../components/layouts/primarylayout";
import { Link } from "react-router-dom";

function Personal() {
  return (
    <>
      <PrimaryLayout>
        {/* Banner */}
        <section
          className="h-80 w-full bg-cover flex flex-col justify-end"
          style={{ backgroundImage: `url(/images/personal-page/banner.jpg)` }}
        ></section>
        {/* Banner */}

        {/* Section 01 */}
        <section className="container mx-auto py-14">
          <h2 className="text-3xl font-bold text-blue-500">
            Personalized Resources at DIT
          </h2>
          <span className="block h-1 w-14 bg-blue-500 mt-3 mb-5"></span>

          <p className="mt-7">
            At Diversified International Trade (DIT), we believe that personal
            touch in business creates long-lasting relationships. Our commitment
            to personal services is at the heart of our operations, ensuring
            that each client receives tailored solutions that resonate with
            their unique needs and aspirations.
          </p>
          <p className="mt-4">
            We understand that personal and business success are intertwined.
            That's why our approach extends beyond traditional commerce,
            fostering personal growth and professional development for our
            clients. Whether it's through individualized asset recovery
            strategies or customized trade solutions, DIT stands as a partner in
            personal and business prosperity.
          </p>
          <p className="mt-4">
            Our dedicated team of professionals excels in providing personal
            service that understands the intricacies of your individual and
            business requirements. This dual focus is what enables us to offer
            comprehensive solutions that contribute not just to your company's
            bottom line, but also to your personal financial health.
          </p>
          <p className="mt-4">
            Integrity, innovation, and excellence drive us to deliver personal
            services that exceed expectations. Join us at DIT, where personal
            business is not just a phrase—it's our promise to you.
          </p>
          <button className="btn bg-blue-600 text-white mt-7">
            Explore Our Services
          </button>
        </section>
        {/* Section 01 */}
      </PrimaryLayout>
    </>
  );
}

export default Personal;
